import React, { useState, useContext, Fragment, useRef } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";
import { Toast } from "primereact/toast";
import Axios from "axios";

const ServiciosContext = createContext();
const { Provider, Consumer } = ServiciosContext;

function ServiciosProvider({ children }) {
  const [numeroTelefono, guardarNumeroTelefono] = useState(null);
  const [valBusqueda, guardarValBusqueda] = useState(null);
  const [rows, setRows] = useState(null);
  const [afiliado, guardarAfiliado] = useState(null);
  const [titular, guardarTitular] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [numeroIndicativo, guardarNumeroIndicativo] = useState(null);
  const [servicio, guardarServicio] = useState(null);
  const [servicioModificado, guardarServicioModificado] = useState(null);
  const [tripulacionModificada, guardarTripulacionModificada] = useState(null);
  const [despachos, guardarDespachos] = useState([]);
  const [tripulaciones, guardarTripulaciones] = useState(null);
  const [tripulacionesCerradas, guardarTripulacionesCerradas] = useState(null);
  const [tripulacion, guardarTripulacion] = useState(null);
  const [tripulacionesProgramacion, guardarTripulacionesProgramacion] =
    useState(null);
  const [resumen, guardarResumen] = useState({
    rojoTotales: 0,
    rojoPendientes: 0,
    verdeTotales: 0,
    verdePendientes: 0,
    amarilloTotales: 0,
    amarilloPendientes: 0,
    azulTotales: 0,
    azulPendientes: 0,
    fucciaTotales: 0,
    fucciaPendientes: 0,
    blancoTotales: 0,
    blancoPendientes: 0,
    negroTotales: 0,
    negroPendientes: 0,
    naranjaTotales: 0,
    naranjaPendientes: 0,
    celesteTotales: 0,
    celestePendientes: 0,
  });
  const [estadoTomado, setEstadoTomado] = useState(true);
  const [estadoTomadoMapa, setEstadoTomadoMapa] = useState(true);
  const [demorados, guardarDemorados] = useState(null);
  const [tripulacionModo, guardarTripulacionModo] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const [servicioSeleccionado, guardarServicioSeleccionado] = useState(null);
  const [cierreEmergencias, guardarCierreEmergencias] = useState(null);
  const [cierreFuccia, guardarCierreFuccia] = useState(null);
  const [cierreMedicamentos, guardarCierreMedicamentos] = useState(null);
  const [chipData, setChipData] = useState([]);
  const [tomaAzul, guardarTomaAzul] = useState(null);
  const [tomaNaranja, guardarTomaNaranja] = useState(null);
  const [openModalAzul, setOpenModalAzul] = useState(false);
  const [openModalFuccia, setOpenModalFuccia] = useState(false);
  const [openModalNaranja, setOpenModalNaranja] = useState(false);
  const [traslados, guardarTraslados] = useState(null);
  const [eventos, guardarEventos] = useState(null);
  const [notas, setNotas] = useState(null);
  const [notaVisible, setNotaVisible] = useState(false);
  const [nota, setNota] = useState(null);
  const [refrescar, setRefrescar] = useState(null);
  const [movilMarcado, setMovilMarcado] = useState(null);
  const [movilesDerivados, guardarMovilesDerivados] = useState(null);
  const [serviciosFinalizados, guardarServiciosFinalizados] = useState(null);
  const [serviciosAnulados, guardarServiciosAnulados] = useState(null);
  const [trasladosServicios, guardarTrasladosServicios] = useState(null);
  const [trasladoServicio, guardarTrasladoServicio] = useState(null);
  const [filtrosListadosServicios, guardarFiltrosListadosServicios] =
    useState(null);
  const [serviciosFinalizadosExcel, setServiciosFinalizadosExcel] =
    useState(null);
  const [serviciosAnuladosExcel, setServiciosAnuladosExcel] = useState(null);
  const [valorizacion, guardarValorizacion] = useState(null);
  const [cantidadServicios, setCantidadServicios] = useState(0);
  const toast = useRef(null);
  const [direcciones, guardarDirecciones] = useState([]);
  const [trasladosServiciosPami, guardarTrasladosServiciosPami] =
    useState(null);
  const [statusEstadoMoviles, setStatusEstadoMoviles] = useState(false);
  const [procesos, guardarProcesos] = useState([]);
  const [openMp, setOpenMp] = useState(false);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const capturarTelefonoPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/anura/obtenernumerotelefono",
        datos
      );
      let data = respuesta.data.numero;
      guardarNumeroTelefono(data);
      guardarValBusqueda(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarTitular = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/buscartitular",
        datos
      );
      let data = respuesta.data;
      guardarTitular(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarValorizacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/buscarvalorizacion",
        datos
      );
      let data = respuesta.data;
      guardarValorizacion(data);
    } catch (error) {
      guardarValorizacion(null);
      console.log(error.response);
    }
  };

  const buscarValorizacionDerivado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/buscarvalorizacionderivado",
        datos
      );
      let data = respuesta.data;
      guardarValorizacion(data);
    } catch (error) {
      guardarValorizacion(null);
      console.log(error.response);
    }
  };

  const obtenerCantidadServiciosTitular = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenercantidadserviciostitular",
        datos
      );
      let data = respuesta.data;

      setCantidadServicios(data);
    } catch (error) {
      setCantidadServicios(0);
      console.log(error.response);
    }
  };

  const obtenerDatosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatosafiliados",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        //BLOQUEO
        if (
          data[i].id_titular !== "00832661" &&
          data[i].id_titular !== "00832944" &&
          data[i].id_titular !== "01000147" &&
          data[i].id_titular !== "00831618" &&
          data[i].id_titular !== "00832633" &&
          data[i].id_titular !== "02006166" &&
          data[i].id_titular !== "00832852"
        ) {
          //FIN BLOQUEO
          local.push({
            id: data[i].id,
            id_titular: data[i].id_titular,
            nombre_razonsocial: data[i].nombre_razonsocial,
            domicilio: data[i].domicilio + " " + data[i].localidades.nombre,
            entreCalles:
              data[i].entre1 &&
              data[i].entre1.trim().length !== 0 &&
              data[i].entre2 &&
              data[i].entre2.trim().length !== 0
                ? data[i].entre1 + " y " + data[i].entre2
                : data[i].entre1 && data[i].entre1.trim().length !== 0
                ? data[i].entre1
                : "",
            telefono1: data[i].telefono1,
            numeroDocumento: data[i].numerodocumento,
            plan: data[i].planes.nombre,
            localidad: data[i].localidades.localidad,
            provincia: data[i].localidades.provincia,
            id_grupo: data[i].id_grupo,
            grupo: data[i].grupos.nombre,
            planGrupo:
              data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
            habilitado: data[i].habilitado,
            id_plan: data[i].id_plan_facturacion,
            coseguroDiurnoPediatrico: data[i].coseguroDiurnoPediatrico,
            coseguroNocturnoPediatrico: data[i].coseguroNocturnoPediatrico,
            coseguroDiurnoAdulto: data[i].coseguroDiurnoAdulto,
            coseguroNocturnoAdulto: data[i].coseguroNocturnoAdulto,
          });
        }
      }

      //console.log(local);

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDatosTitulares = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatostitulares",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.localidad + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDatosConvenios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatosconvenios",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.localidad + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const agregarNuevoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnuevoservicio",
        datos
      );
      let data = respuesta.data;
      mensajeAlerta("Servicio", respuesta.data.cartel, "success");
      guardarNumeroIndicativo(data.indicativo);
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Servicio", error.response.data, "error");
    }
  };

  const agregarNuevoServicioPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnuevoserviciopendiente",
        datos
      );
      mensajeAlerta("Servicio", respuesta.data.cartel, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Servicio", error.response.data, "error");
    }
  };

  const verificarEstadoMoviles = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/verificarestadomoviles"
      );
      setRefrescar(new Date());
      setStatusEstadoMoviles(true);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServiciosPendientes = async (data) => {
    let datos = {
      zona: localStorage.getItem("zona"),
      color: localStorage.getItem("colores"),
      movil: localStorage.getItem("moviles"),
    };

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciospendientes",
        datos
      );
      let data = respuesta.data;

      const local = [];
      let dTomado;
      let dPasado;
      let dSalida;
      let dLlegada;
      let d2;
      let newDate;
      let rojoTotales = 0;
      let rojoPendientes = 0;
      let amarilloTotales = 0;
      let amarilloPendientes = 0;
      let verdeTotales = 0;
      let verdePendientes = 0;
      let azulTotales = 0;
      let azulPendientes = 0;
      let fucciaTotales = 0;
      let fucciaPendientes = 0;
      let blancoTotales = 0;
      let blancoPendientes = 0;
      let negroTotales = 0;
      let negroPendientes = 0;
      let naranjaTotales = 0;
      let naranjaPendientes = 0;
      let celesteTotales = 0;
      let celestePendientes = 0;

      for (var i = 0; i < data.length; i++) {
        dTomado = data[i].ftomado ? data[i].ftomado : null;
        dPasado = data[i].fpasado ? data[i].fpasado : null;
        dSalida = data[i].fsalida ? data[i].fsalida : null;
        dLlegada = data[i].fllegada ? data[i].fllegada : null;
        d2 = new Date();
        newDate = new Date(d2);
        newDate.setHours(d2.getHours());

        local.push({
          id: data[i].id,
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          colorInicio: data[i].colorInicio,
          grupo:
            data[i].grupos.nombre === "CONVENIOS" ||
            data[i].grupos.nombre === "COLEGIOS"
              ? data[i].grupos.nombre + " / " + data[i].nombreTitular
              : data[i].grupos.nombre,
          grupoId: data[i].grupos.id,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          localidad: data[i].localidad,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          planId: data[i].planes.id_codigo,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          fueraZona: data[i].fueraZona,
          reclamado: data[i].reclamado,
          auditado: data[i].auditado,
          nuevoServicio: data[i].nuevoServicio,
          generaNuevo: data[i].generaNuevo,
          videollamada: data[i].videollamada,
          celular: data[i].celular,
          rederivado: data[i].rederivado,
          indicativoRederivado: data[i].indicativoRederivado,
          lat: data[i].lat,
          lon: data[i].lon,
          linkMp: data[i].linkMp,
          alertaCode: data[i].alertaCode,
          derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
          tripulacionId:
            data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
          movilId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.movilId
              : data[i].derivadoId !== null
              ? data[i].derivadoId
              : null,
          detalleDerivado:
            data[i].detalleDerivado !== null ? data[i].detalleDerivado : null,

          almacenId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.almacenId
              : null,

          tomado:
            dTomado !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dTomado) + " " + diff_minutes(dTomado, d2)
                : formatDateTime(dTomado)
              : null,
          pasado:
            dPasado !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dPasado) + " " + diff_minutes(dPasado, d2)
                : formatDateTime(dPasado)
              : null,
          salida:
            dSalida !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dSalida) + " " + diff_minutes(dSalida, d2)
                : formatDateTime(dSalida)
              : null,
          llegada:
            dLlegada !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dLlegada) + " " + diff_minutes(dLlegada, d2)
                : formatDateTime(dLlegada)
              : null,

          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,
         coseguroAbonado:
            data[i].coseguroAbonado !== undefined ? data[i].coseguroAbonado : 0,

          usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
          usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
          usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
          usuarioLlegada: data[i].usuarioLlegada
            ? data[i].usuarioLlegada
            : null,

          //Tripulacion

          medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          movilDetalle:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.detalle
              : null,
          telefonoTripulacion:
            data[i].tripulaciones && data[i].tripulaciones.telefonos
              ? data[i].tripulaciones.telefonos.nombre
              : null,
          // Traslados Servicios
          trasladoServicio:
            data[i].trasladoServicio !== null ? data[i].trasladoServicio : null,
          // Traslados Programados
          traslados: data[i].traslados !== null ? data[i].traslados : null,
          // Eventos Programados
          eventos: data[i].eventos !== null ? data[i].eventos : null,
          // Laborales
          laborales: data[i].laborales !== null ? data[i].laborales : null,
          recepcionApp:
            data[i].recepcionApp !== null ? data[i].recepcionApp : null,
        });

        // Inicializo Resumen

        if (data[i].color === "ROJO") {
          rojoTotales = rojoTotales + 1;
          if (data[i].estado === "TOMADO") {
            rojoPendientes = rojoPendientes + 1;
          }
        }

        if (data[i].color === "AMARILLO") {
          amarilloTotales = amarilloTotales + 1;
          if (data[i].estado === "TOMADO") {
            amarilloPendientes = amarilloPendientes + 1;
          }
        }

        if (data[i].color === "VERDE") {
          verdeTotales = verdeTotales + 1;
          if (data[i].estado === "TOMADO") {
            verdePendientes = verdePendientes + 1;
          }
        }

        if (data[i].color === "AZUL") {
          azulTotales = azulTotales + 1;
          if (data[i].estado === "TOMADO") {
            azulPendientes = azulPendientes + 1;
          }
        }

        if (data[i].color === "FUCCIA") {
          fucciaTotales = fucciaTotales + 1;
          if (data[i].estado === "TOMADO") {
            fucciaPendientes = fucciaPendientes + 1;
          }
        }

        if (data[i].color === "BLANCO") {
          blancoTotales = blancoTotales + 1;
          if (data[i].estado === "TOMADO") {
            blancoPendientes = blancoPendientes + 1;
          }
        }

        if (data[i].color === "NEGRO") {
          negroTotales = negroTotales + 1;
          if (data[i].estado === "TOMADO") {
            negroPendientes = negroPendientes + 1;
          }
        }

        if (data[i].color === "NARANJA") {
          naranjaTotales = naranjaTotales + 1;
          if (data[i].estado === "TOMADO") {
            naranjaPendientes = naranjaPendientes + 1;
          }
        }

        if (data[i].color === "CELESTE") {
          celesteTotales = celesteTotales + 1;
          if (data[i].estado === "TOMADO") {
            celestePendientes = celestePendientes + 1;
          }
        }
      }

      guardarResumen({
        rojoTotales,
        rojoPendientes,
        amarilloTotales,
        amarilloPendientes,
        verdeTotales,
        verdePendientes,
        azulTotales,
        azulPendientes,
        fucciaTotales,
        fucciaPendientes,
        blancoTotales,
        blancoPendientes,
        negroTotales,
        negroPendientes,
        naranjaTotales,
        naranjaPendientes,
        celesteTotales,
        celestePendientes,
      });
      guardarDespachos(local);
      setEstadoTomado(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerservicio",
        datos
      );
      let data = respuesta.data;
      guardarServicioModificado({
        ...data,
        motivoConsultaNombre: data.motivosConsultas.nombre,
        motivoConsultaCodigoColor:
          data.motivosConsultas.id_color === 1
            ? "#000000"
            : data.motivosConsultas.id_color === 2
            ? "#FFFFFF"
            : data.motivosConsultas.id_color === 3
            ? "#FFFF00"
            : data.motivosConsultas.id_color === 4
            ? "#008F39"
            : data.motivosConsultas.id_color === 5
            ? "#FF0000"
            : data.motivosConsultas.id_color === 6
            ? "#0000FF"
            : data.motivosConsultas.id_color === 7
            ? "#E30052"
            : data.motivosConsultas.id_color === 8
            ? "#FF8000"
            : data.motivosConsultas.id_color === 9
            ? "#00FFFF"
            : data.motivosConsultas.id_color === 12
            ? "#00FFFF"
            : null,

        motivoConsultaColor:
          data.motivosConsultas.id_color === 1
            ? "NEGRO"
            : data.motivosConsultas.id_color === 2
            ? "BLANCO"
            : data.motivosConsultas.id_color === 3
            ? "AMARILLO"
            : data.motivosConsultas.id_color === 4
            ? "VERDE"
            : data.motivosConsultas.id_color === 5
            ? "ROJO"
            : data.motivosConsultas.id_color === 6
            ? "AZUL"
            : data.motivosConsultas.id_color === 7
            ? "FUCCIA"
            : data.motivosConsultas.id_color === 8
            ? "NARANJA"
            : data.motivosConsultas.id_color === 9
            ? "CELESTE"
            : data.motivosConsultas.id_color === 12
            ? "CELESTE"
            : null,
        provincia: "Buenos Aires",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServicioFinalizado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciofinalizado",
        datos
      );
      let data = respuesta.data;

      //console.log(data)
      guardarServicioModificado({
        ...data,
        motivoConsultaNombre: data.motivosConsultas.nombre,
        motivoConsultaCodigoColor:
          data.motivosConsultas.id_color === 1
            ? "#000000"
            : data.motivosConsultas.id_color === 2
            ? "#FFFFFF"
            : data.motivosConsultas.id_color === 3
            ? "#FFFF00"
            : data.motivosConsultas.id_color === 4
            ? "#008F39"
            : data.motivosConsultas.id_color === 5
            ? "#FF0000"
            : data.motivosConsultas.id_color === 6
            ? "#0000FF"
            : data.motivosConsultas.id_color === 7
            ? "#E30052"
            : data.motivosConsultas.id_color === 8
            ? "#FF8000"
            : data.motivosConsultas.id_color === 9
            ? "#00FFFF"
            : data.motivosConsultas.id_color === 12
            ? "#00FFFF"
            : null,

        motivoConsultaColor:
          data.motivosConsultas.id_color === 1
            ? "NEGRO"
            : data.motivosConsultas.id_color === 2
            ? "BLANCO"
            : data.motivosConsultas.id_color === 3
            ? "AMARILLO"
            : data.motivosConsultas.id_color === 4
            ? "VERDE"
            : data.motivosConsultas.id_color === 5
            ? "ROJO"
            : data.motivosConsultas.id_color === 6
            ? "AZUL"
            : data.motivosConsultas.id_color === 7
            ? "FUCCIA"
            : data.motivosConsultas.id_color === 8
            ? "NARANJA"
            : data.motivosConsultas.id_color === 9
            ? "CELESTE"
            : data.motivosConsultas.id_color === 12
            ? "CELESTE"
            : null,
        diagnosticoNombre: data.diagnosticos.nombre,
        provincia: "Buenos Aires",
        tomado: data.ftomado.slice(0, -8),
        pasado: data.fpasado.slice(0, -8),
        salida: data.fsalida.slice(0, -8),
        llegada: data.fllegada.slice(0, -8),
        fin: data.ffin.slice(0, -8),
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  function diff_minutes(dt2, dt1) {
    let d1 = new Date(dt2);
    let d11 = new Date(d1);
    d11.setHours(d1.getHours() + 3);

    var diff = (d11.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  function tarifaPami(datos) {
    const { color, tomado, demora, feriados, motivoConsulta } = datos;

    let tarifa = 0;

    var allDate = tomado.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");

    let dArr = newDate.split("/");
    let semana = dArr[2] + "/" + dArr[1] + "/" + dArr[0];

    let diaSemana = new Date(semana).getDay();
    const index = feriados.findIndex((feriado) => feriado.fecha === newDate);
    let time1 = newTime.split(":");
    let hora = Number(time1[0]);
    const nocturno = hora >= 19 || hora < 7;

    /*
    console.log(newDate);
    console.log(newTime);
    console.log(feriados);
    console.log(diaSemana)
    */

    let tarifa1Verde = 15917.09;
    let tarifa2Verde = 19100.51;
    let tarifa3Verde = 22920.61;
    let tarifa4Verde = 22283.93;
    let tarifa5Verde = 26740.71;
    //let tarifa6Verde = 3721.79;
    //let tarifa7Verde = 3190.1;

    if (color === "VERDE") {
      if (demora > 360) {
        if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
              tarifa = tarifa1Verde;
            } else {
              tarifa = tarifa2Verde;
            }
          }
        } else {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            tarifa = tarifa1Verde;
          }
        }
      } else {
        if (demora <= 120) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Verde;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Verde;
              } else {
                tarifa = tarifa5Verde;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Verde;
            } else {
              tarifa = tarifa4Verde;
            }
          }
        } else {
          if (demora > 120 && demora <= 180) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa3Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa2Verde;
                } else {
                  tarifa = tarifa3Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa3Verde;
              } else {
                tarifa = tarifa2Verde;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Verde;
                } else {
                  tarifa = tarifa2Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                tarifa = tarifa1Verde;
              }
            }
          }
        }
      }
    }

    let tarifa1Amarillo = 43739.34;
    let tarifa2Amarillo = 52487.21;
    let tarifa3Amarillo = 62984.65;
    let tarifa4Amarillo = 61235.08;
    let tarifa5Amarillo = 73482.09;
    //let tarifa6Amarillo = 10227.25;
    //let tarifa7Amarillo = 8766.22;

    if (color === "AMARILLO") {
      if (demora > 180) {
        if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
              tarifa = tarifa1Verde;
            } else {
              tarifa = tarifa2Verde;
            }
          }
        } else {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            tarifa = tarifa1Verde;
          }
        }
      } else {
        if (demora <= 45) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Amarillo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Amarillo;
              } else {
                tarifa = tarifa5Amarillo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Amarillo;
            } else {
              tarifa = tarifa4Amarillo;
            }
          }
        } else {
          if (demora > 45 && demora <= 90) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa3Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa2Amarillo;
                } else {
                  tarifa = tarifa3Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa3Amarillo;
              } else {
                tarifa = tarifa2Amarillo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Amarillo;
                } else {
                  tarifa = tarifa2Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                tarifa = tarifa1Amarillo;
              }
            }
          }
        }
      }
    }

    let tarifa1Rojo = 63028.4;
    let tarifa2Rojo = 81936.92;
    //let tarifa3Rojo = 14022.4;
    let tarifa4Rojo = 88239.76;
    let tarifa5Rojo = 114711.69;
    //let tarifa6Rojo = 19631.36;
    let tarifa7Rojo = 75634.08;
    let tarifa8Rojo = 98324.3;
    //let tarifa9Rojo = 16826.89;

    if (color === "ROJO") {
      if (demora > 60) {
        if (demora > 60 && demora <= 90) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa2Amarillo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa1Amarillo;
              } else {
                tarifa = tarifa2Amarillo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa2Amarillo;
            } else {
              tarifa = tarifa1Amarillo;
            }
          }
        } else {
          if (demora > 90 && demora <= 180) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Amarillo;
                } else {
                  tarifa = tarifa2Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                tarifa = tarifa1Amarillo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Verde;
                } else {
                  tarifa = tarifa2Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                tarifa = tarifa1Verde;
              }
            }
          }
        }
      } else {
        if (demora <= 15) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Rojo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Rojo;
              } else {
                tarifa = tarifa5Rojo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Rojo;
            } else {
              tarifa = tarifa4Rojo;
            }
          }
        } else {
          if (demora > 15 && demora <= 30) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa8Rojo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa7Rojo;
                } else {
                  tarifa = tarifa8Rojo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa8Rojo;
              } else {
                tarifa = tarifa7Rojo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Rojo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Rojo;
                } else {
                  tarifa = tarifa2Rojo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Rojo;
              } else {
                tarifa = tarifa1Rojo;
              }
            }
          }
        }
      }
    }

    let tarifa1Celeste = 11967.95;
    let tarifa2Celeste = 23935.89;
    let tarifa3Celeste = 19231.23;
    let tarifa4Celeste = 38475.9;
    let tarifa5Celeste = 16028.42;
    let tarifa6Celeste = 32064.73;
    let tarifa7Celeste = 29926.58;
    let tarifa8Celeste = 59845.29;
    let tarifa9Celeste = 64123.9;
    let tarifa10Celeste = 128239.89;
    let tarifa11Celeste = 53428.53;
    let tarifa12Celeste = 106849.18;

    let tarifa13Celeste = 15551.77;
    let tarifa14Celeste = 31111.46;
    let tarifa15Celeste = 25011.69;
    let tarifa16Celeste = 50009.92;
    let tarifa17Celeste = 20828.63;
    let tarifa18Celeste = 41662.93;
    let tarifa19Celeste = 38901.95;
    let tarifa20Celeste = 77803.94;
    let tarifa23Celeste = 69456.96;
    let tarifa24Celeste = 138916.17;

    if (color === "CELESTE") {
      if (motivoConsulta === 2039) {
        tarifa = tarifa2Celeste;
      }
      if (motivoConsulta === 2041) {
        tarifa = tarifa6Celeste;
      }
      if (motivoConsulta === 2042) {
        tarifa = tarifa1Celeste;
      }
      if (motivoConsulta === 2045) {
        tarifa = tarifa5Celeste;
      }
      if (motivoConsulta === 2048) {
        tarifa = tarifa4Celeste;
      }
      if (motivoConsulta === 2049) {
        tarifa = tarifa3Celeste;
      }
      if (motivoConsulta === 2052) {
        tarifa = tarifa8Celeste;
      }
      if (motivoConsulta === 2053) {
        tarifa = tarifa7Celeste;
      }
      if (motivoConsulta === 2057) {
        tarifa = tarifa12Celeste;
      }

      if (motivoConsulta === 2043) {
        tarifa = tarifa14Celeste;
      }
      if (motivoConsulta === 2044) {
        tarifa = tarifa13Celeste;
      }
      if (motivoConsulta === 2046) {
        tarifa = tarifa18Celeste;
      }
      if (motivoConsulta === 2047) {
        tarifa = tarifa17Celeste;
      }
      if (motivoConsulta === 2050) {
        tarifa = tarifa16Celeste;
      }
      if (motivoConsulta === 2051) {
        tarifa = tarifa15Celeste;
      }
      if (motivoConsulta === 2054) {
        tarifa = tarifa20Celeste;
      }
      if (motivoConsulta === 2055) {
        tarifa = tarifa19Celeste;
      }
      if (motivoConsulta === 2056) {
        tarifa = tarifa24Celeste;
      }
    }

    return tarifa;
  }

  const obtenerServiciosFinalizados = async (datos) => {
    const { destinatario } = datos;

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosfinalizados",
        datos
      );
      let data = respuesta.data.response;
      let feriados = respuesta.data.response2;

      for (var i = 0; i < feriados.length; i++) {
        let dArr = feriados[i].fecha.split("-");
        feriados[i].fecha = dArr[2] + "/" + dArr[1] + "/" + dArr[0];
      }

      const local = [];
      if (
        datos.traslado === undefined ||
        datos.traslado === null ||
        datos.traslado === false
      ) {
        for (var i = 0; i < data.length; i++) {
          local.push({
            id: data[i].id,
            indicativo: data[i].indicativo,
            indicativoId: data[i].indicativo + " - " + data[i].id,
            color: data[i].color,
            colorInicio: data[i].colorInicio,
            grupo: data[i].grupos.nombre,
            domicilio:
              data[i].entreCalles === ""
                ? data[i].domicilio
                : data[i].domicilio + " entre " + data[i].entreCalles,
            telefono: data[i].telefono,
            nombre: data[i].nombre,
            estado: data[i].estado,
            titularId: data[i].titularId,
            afiliadoId: data[i].afiliadoId,
            plan: data[i].planes.nombre,
            nombreTitular: data[i].nombreTitular,
            numeroDocumento: data[i].numeroDocumento,
            edad: data[i].edad,
            sexo: data[i].sexo,
            antecedente: data[i].antecedentes.nombre,
            tratamiento: data[i].tratamientos.nombre,
            motivoConsulta: data[i].motivosConsultas.nombre,
            diagnostico:
              data[i].diagnosticos !== null
                ? data[i].diagnosticos.nombre
                : null,
            detalleDiagnostico: data[i].detalleDiagnostico
              ? data[i].detalleDiagnostico
              : null,
            operadorEmisor: data[i].operadorEmisor,
            indicativoEmisor: data[i].indicativoEmisor,
            obraSocial: data[i].obraSocial,
            nroObraSocial: data[i].nroObraSocial,
            covid: data[i].covid,
            videollamada: data[i].videollamada,
            celular: data[i].celular,
            rederivado: data[i].rederivado,
            indicativoRederivado: data[i].indicativoRederivado,
            auditado: data[i].auditado,
            lat: data[i].lat,
            lon: data[i].lon,
            derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
            derivado: data[i].moviles !== null ? data[i].moviles.nombre : null,
            tripulacionId:
              data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
            movilId:
              data[i].tripulaciones !== null
                ? data[i].tripulaciones.movilId
                : data[i].derivadoId !== null
                ? data[i].derivadoId
                : null,
            tomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
            pasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
            salida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
            llegada: data[i].fllegada ? formatDateTime(data[i].fllegada) : null,
            fin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
            anulado: data[i].fanulado ? formatDateTime(data[i].fanulado) : null,
            coseguroAbonar:
              data[i].coseguroAbonar !== undefined
                ? Number(data[i].coseguroAbonar)
                : 0,
            usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
            usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
            usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
            usuarioLlegada: data[i].usuarioLlegada
              ? data[i].usuarioLlegada
              : null,
            usuarioFin: data[i].usuarioFin ? data[i].usuarioFin : null,
            usuarioAnulado: data[i].usuarioAnulado
              ? data[i].usuarioAnulado
              : null,
            coseguroAbonado:
              data[i].coseguroAbonado !== undefined
                ? Number(data[i].coseguroAbonado)
                : 0,

            //Tripulacion

            medico:
              data[i].tripulaciones && data[i].tripulaciones.medicos
                ? data[i].tripulaciones.medicos.nombre
                : null,
            chofer:
              data[i].tripulaciones && data[i].tripulaciones.choferes
                ? data[i].tripulaciones.choferes.nombre
                : null,
            enfermero:
              data[i].tripulaciones && data[i].tripulaciones.enfermeros
                ? data[i].tripulaciones.enfermeros.nombre
                : null,
            base:
              data[i].tripulaciones && data[i].tripulaciones.bases
                ? data[i].tripulaciones.bases.nombre
                : null,
            movil:
              data[i].tripulaciones && data[i].tripulaciones.moviles
                ? data[i].tripulaciones.moviles.nombre
                : null,
            telefonoTripulacion:
              data[i].tripulaciones && data[i].tripulaciones.telefonos
                ? data[i].tripulaciones.telefonos.nombre
                : null,
            // Laborales
            laborales: data[i].laborales !== null ? data[i].laborales : null,
            // Traslados
            trasladoServicio:
              data[i].trasladoServicio !== null
                ? data[i].trasladoServicio
                : null,
            // Eventos
            eventos: data[i].eventos !== null ? data[i].eventos : null,
            //H.C.
            hcServicio: data[i].hcServicio ? data[i].hcServicio : null,
            hcAdjunto: data[i].hcAdjunto ? data[i].hcAdjunto : null,
            //Colores
            colores: data[i].colores !== null ? data[i].colores : null,
            localidad: data[i].localidad ? data[i].localidad : null,
          });
        }
      }
      guardarServiciosFinalizados(local);

      //console.log(local);

      const localExcel = [];

      if (
        datos.traslado !== undefined &&
        datos.traslado !== null &&
        datos.traslado === true
      ) {
        for (var i = 0; i < data.length; i++) {
          localExcel.push({
            ...(destinatario !== undefined &&
            destinatario !== null &&
            destinatario === 2
              ? {
                  id: data[i].serviciosfinalizados.id,
                  indicativo: data[i].serviciosfinalizados.id,
                  indicativoId:
                    data[i].serviciosfinalizados.indicativo +
                    " - " +
                    data[i].serviciosfinalizados.id,
                }
              : {
                  id: data[i].serviciosfinalizados.id,
                  indicativo: data[i].serviciosfinalizados.id,
                }),

            //id: data[i].serviciosfinalizados.id,
            //indicativo: data[i].serviciosfinalizados.id,
            /*
            indicativoId:
              data[i].serviciosfinalizados.indicativo +
              " - " +
              data[i].serviciosfinalizados.id,
            */
            color: data[i].serviciosfinalizados.color,
            colorInicio: data[i].serviciosfinalizados.colorInicio,
            domicilio:
              data[i].serviciosfinalizados.entreCalles === ""
                ? data[i].serviciosfinalizados.domicilio
                : data[i].serviciosfinalizados.domicilio +
                  " entre " +
                  data[i].serviciosfinalizados.entreCalles,
            telefono: data[i].serviciosfinalizados.telefono,
            nombre: data[i].serviciosfinalizados.nombre,
            estado: data[i].serviciosfinalizados.estado,
            titularId: data[i].serviciosfinalizados.titularId,
            afiliadoId: data[i].serviciosfinalizados.afiliadoId,
            nombreTitular: data[i].serviciosfinalizados.nombreTitular,
            numeroDocumento: data[i].serviciosfinalizados.numeroDocumento,
            edad: data[i].serviciosfinalizados.edad,
            sexo: data[i].serviciosfinalizados.sexo,
            destino: data[i].destino,
            distancia: data[i].distancia,
          });
        }
      } else {
        for (var i = 0; i < data.length; i++) {
          localExcel.push({
            ...(destinatario !== undefined &&
            destinatario !== null &&
            destinatario === 2
              ? {
                  Id: data[i].id,
                  Indicativo: data[i].indicativo,
                  IndicativoId: data[i].indicativo + " - " + data[i].id,
                }
              : { Indicativo: data[i].id }),

            //id: data[i].id,
            //Indicativo: data[i].id,
            FechaTomado: data[i].ftomado
              ? formatDateTime(data[i].ftomado)
              : null,
            FechaPasado: data[i].fpasado
              ? formatDateTime(data[i].fpasado)
              : null,
            FechaSalida: data[i].fsalida
              ? formatDateTime(data[i].fsalida)
              : null,
            FechaLlegada: data[i].fllegada
              ? formatDateTime(data[i].fllegada)
              : null,
            FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
            usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
            usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
            usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
            usuarioLlegada: data[i].usuarioLlegada
              ? data[i].usuarioLlegada
              : null,
            usuarioFin: data[i].usuarioFin ? data[i].usuarioFin : null,
            IDTitular: data[i].titularId,
            NombreTitular: data[i].nombreTitular,
            IDAfiliado: data[i].afiliadoId,
            NombreAfiliado: data[i].nombre,
            Domicilio: data[i].domicilio,
            Localidad: data[i].localidad,
            Telefono: data[i].telefono,
            Documento: data[i].numeroDocumento,
            Edad: data[i].edad,
            Sexo: data[i].sexo,
            Grupo: data[i].grupos.nombre,
            Rubro:
              data[i].afiliados && data[i].afiliados.rubros
                ? data[i].afiliados.rubros.nombre
                : null,
            Plan: data[i].planes.nombre,
            Antecedente: data[i].antecedentes.nombre,
            Tratamiento: data[i].tratamientos.nombre,
            MotivoConsulta: data[i].motivosConsultas.nombre,
            Diagnostico:
              data[i].diagnosticos !== null
                ? data[i].diagnosticos.nombre
                : null,
            DetalleDiagnostico: data[i].detalleDiagnostico,
            ColorInicial: data[i].colorInicio,
            ColorFinal: data[i].color,
            Movil:
              data[i].tripulaciones && data[i].tripulaciones.moviles
                ? data[i].tripulaciones.moviles.nombre
                : null,
            Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
            DetalleDerivado:
              data[i].moviles !== null ? data[i].moviles.nombre : null,
            Base:
              data[i].tripulaciones && data[i].tripulaciones.bases
                ? data[i].tripulaciones.bases.nombre
                : null,
            Medico:
              data[i].tripulaciones && data[i].tripulaciones.medicos
                ? data[i].tripulaciones.medicos.nombre
                : null,
            Chofer:
              data[i].tripulaciones && data[i].tripulaciones.choferes
                ? data[i].tripulaciones.choferes.nombre
                : null,
            Enfermero:
              data[i].tripulaciones && data[i].tripulaciones.enfermeros
                ? data[i].tripulaciones.enfermeros.nombre
                : null,
            CoseguroAbonar: Number(data[i].coseguroAbonar),
            CoseguroAbonado: Number(data[i].coseguroAbonado),
            ...(destinatario !== undefined &&
              destinatario !== null &&
              destinatario === 4
              ? {
                formaPago: data[i].formaPago ? data[i].formaPago : null,
              }
            : {}),
            ObraSocialConvenio: data[i].obraSocial,
            NroObraSocial: data[i].nroObraSocial,
            Covid: data[i].covid ? "SI" : "NO",
            Auditado: data[i].auditado ? "SI" : "NO",
            ValorServicio:
              data[i].titularId !== "02006423"
                ? data[i].valorizacion
                : tarifaPami({
                    color: data[i].colorInicio,
                    tomado: data[i].ftomado,
                    demora: data[i].demoraLlegada ? data[i].demoraLlegada : 0,
                    feriados,
                    motivoConsulta: data[i].motivosConsultas.id,
                  }),
            IndicativoEmisor: data[i].indicativoEmisor,
            Traslado: data[i].trasladoServicio ? "SI" : "NO",
            VideoLLamada: data[i].videollamada ? "SI" : "NO",
            FechaTrabaja:
              data[i].laborales && data[i].laborales.trabaja
                ? data[i].laborales.fechaTrabaja
                : null,
            FechaCitado:
              data[i].laborales && data[i].laborales.citado
                ? data[i].laborales.fechaCitado
                : null,
            DemoraSalida: data[i].demoraSalida ? data[i].demoraSalida : 0,
            DemoraLlegada: data[i].demoraLlegada ? data[i].demoraLlegada : 0,
            DemoraToleranciaSalida:
              data[i].colores && data[i].colores.demoraSalida
                ? data[i].colores.demoraSalida
                : 0,
            DemoraToleranciaLlegada:
              data[i].colores && data[i].colores.demoraLlegada
                ? data[i].colores.demoraLlegada
                : 0,
            Empresa:
              data[i].afiliados &&
              data[i].afiliados.empresas &&
              data[i].afiliados.empresas.nombre
                ? data[i].afiliados.empresas.nombre
                : "",
            Cuota:
              data[i].afiliados && data[i].afiliados.cuota
                ? data[i].afiliados.cuota
                : 0,
            });
        }
      }

      toast.current.clear();

      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServiciosAnulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosanulados",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          indicativo: data[i].id,
          //indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          colorInicio: data[i].colorInicio,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          detalleDiagnostico: data[i].detalleDiagnostico
            ? data[i].detalleDiagnostico
            : null,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          videollamada: data[i].videollamada,
          celular: data[i].celular,
          rederivado: data[i].rederivado,
          indicativoRederivado: data[i].indicativoRederivado,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
          tripulacionId:
            data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
          movilId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.movilId
              : data[i].derivadoId !== null
              ? data[i].derivadoId
              : null,
          tomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          pasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          salida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          llegada: data[i].fllegada ? formatDateTime(data[i].fllegada) : null,
          fin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          anulado: data[i].fanulado ? formatDateTime(data[i].fanulado) : null,
          motivoAnulado: data[i].motivoAnulado ? data[i].motivoAnulado : null,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined
              ? Number(data[i].coseguroAbonar)
              : 0,
          usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
          usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
          usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
          usuarioLlegada: data[i].usuarioLlegada
            ? data[i].usuarioLlegada
            : null,

          usuarioFin: data[i].usuarioFin ? data[i].usuarioFin : null,
          usuarioAnulado: data[i].usuarioAnulado
            ? data[i].usuarioAnulado
            : null,
          coseguroAbonado:
            data[i].coseguroAbonado !== undefined
              ? Number(data[i].coseguroAbonado)
              : 0,

          //Tripulacion

          medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          telefonoTripulacion:
            data[i].tripulaciones && data[i].tripulaciones.telefonos
              ? data[i].tripulaciones.telefonos.nombre
              : null,
          // Laborales
          laborales: data[i].laborales !== null ? data[i].laborales : null,
          // Traslados
          trasladoServicio:
            data[i].trasladoServicio !== null ? data[i].trasladoServicio : null,
          // Eventos
          eventos: data[i].eventos !== null ? data[i].eventos : null,
          //H.C.
          hcServicio: data[i].hcServicio ? data[i].hcServicio : null,
          hcAdjunto: data[i].hcAdjunto ? data[i].hcAdjunto : null,
          //Colores
          colores: data[i].colores !== null ? data[i].colores : null,
        });
      }
      guardarServiciosAnulados(local);

      //console.log(local);

      const localExcel = [];

      for (var i = 0; i < data.length; i++) {
        localExcel.push({
          //id: data[i].id,
          Indicativo: data[i].id,
          FechaTomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          FechaPasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          FechaSalida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          FechaLlegada: data[i].fllegada
            ? formatDateTime(data[i].fllegada)
            : null,
          FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          FechaAnulado: data[i].fanulado
            ? formatDateTime(data[i].fanulado)
            : null,
          UsuarioAnulado: data[i].usuarioAnulado,
          IDTitular: data[i].titularId,
          NombreTitular: data[i].nombreTitular,
          IDAfiliado: data[i].afiliadoId,
          NombreAfiliado: data[i].nombre,
          Domicilio: data[i].domicilio,
          Telefono: data[i].telefono,
          Documento: data[i].numeroDocumento,
          Edad: data[i].edad,
          Sexo: data[i].sexo,
          Plan: data[i].planes.nombre,
          Antecedente: data[i].antecedentes.nombre,
          Tratamiento: data[i].tratamientos.nombre,
          MotivoConsulta: data[i].motivosConsultas.nombre,
          Diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          DetalleDiagnostico: data[i].detalleDiagnostico,
          ColorInicial: data[i].colorInicio,
          ColorFinal: data[i].color,
          Movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
          Base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          Medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          Chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          Enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          CoseguroAbonar: data[i].coseguroAbonar,
          CoseguroAbonado: data[i].coseguroAbonado,
          ObraSocialConvenio: data[i].obraSocial,
          NroObraSocial: data[i].nroObraSocial,
          Covid: data[i].covid ? "SI" : "NO",
          ValorServicio: data[i].valorizacion,
          Traslado: data[i].trasladoServicio ? "SI" : "NO",
          VideoLLamada: data[i].videollamada ? "SI" : "NO",
          FechaTrabaja:
            data[i].laborales && data[i].laborales.trabaja
              ? data[i].laborales.fechaTrabaja
              : null,
          FechaCitado:
            data[i].laborales && data[i].laborales.citado
              ? data[i].laborales.fechaCitado
              : null,
          DemoraSalida: data[i].demoraSalida ? data[i].demoraSalida : 0,
          DemoraLlegada: data[i].demoraLlegada ? data[i].demoraLlegada : 0,
          DemoraToleranciaSalida:
            data[i].colores && data[i].colores.demoraSalida
              ? data[i].colores.demoraSalida
              : 0,
          DemoraToleranciaLlegada:
            data[i].colores && data[i].colores.demoraLlegada
              ? data[i].colores.demoraLlegada
              : 0,
        });
      }

      setServiciosAnuladosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const obtenerServiciosSemanales = async (datos) => {
    const { periodo } = datos;

    let periodo2 = periodo.toISOString();

    let mes = Number(periodo2.substring(5, 7));
    let anio = Number(periodo2.substring(0, 4));

    let date1 = new Date(
      anio + "-" + String(mes).padStart(2, 0) + "-08T00:00:00.000Z"
    );
    let date2 = new Date(
      anio + "-" + String(mes).padStart(2, 0) + "-15T00:00:00.000Z"
    );
    let date3 = new Date(
      anio + "-" + String(mes).padStart(2, 0) + "-22T00:00:00.000Z"
    );

    let date4 = new Date(
      anio + "-" + String(mes).padStart(2, 0) + "-29T00:00:00.000Z"
    );

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciossemanales",
        datos
      );

      let data = respuesta.data;
      //console.log(data)

      let local = [];
      for (var i = 0; i < data.length; i++) {
        //var d = data[i].ftomado.substring(0, 10);
        //var day = d.substring(8, 10);
        var d = new Date(data[i].ftomado);

        local.push({
          nombre: data[i].nombreTitular,
          titularId: data[i].titularId,
          semana:
            d < date1
              ? 1
              : d >= date1 && d < date2
              ? 2
              : d >= date2 && d < date3
              ? 3
              : d >= date3 && d < date4
              ? 4
              : 5,
        });
      }

      const result = Object.entries(groupBy("nombre")(local)).map(
        ([key, value]) => ({ nombre: key, datos: value })
      );

      result.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      //console.log(result);

      let local2 = [];
      for (var i = 0; i < result.length; i++) {
        const countSemana1 = result[i].datos.filter((obj) => {
          if (obj.semana === 1) {
            return true;
          }

          return false;
        }).length;

        //console.log(countSemana1)

        const countSemana2 = result[i].datos.filter((obj) => {
          if (obj.semana === 2) {
            return true;
          }

          return false;
        }).length;

        const countSemana3 = result[i].datos.filter((obj) => {
          if (obj.semana === 3) {
            return true;
          }

          return false;
        }).length;

        const countSemana4 = result[i].datos.filter((obj) => {
          if (obj.semana === 4) {
            return true;
          }

          return false;
        }).length;

        const countSemana5 = result[i].datos.filter((obj) => {
          if (obj.semana === 5) {
            return true;
          }

          return false;
        }).length;

        local2.push({
          nombre: result[i].nombre,
          semana1: countSemana1,
          semana2: countSemana2,
          semana3: countSemana3,
          semana4: countSemana4,
          semana5: countSemana5,
          totalPeriodo:
            countSemana1 +
            countSemana2 +
            countSemana3 +
            countSemana4 +
            countSemana5,
        });
      }

      toast.current.clear();

      setServiciosFinalizadosExcel(local2);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServiciosAnual = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosanual",
        datos
      );
      let data = respuesta.data;

      //console.log(data)

      const localExcel = [];
      localExcel.push({
        mes: "ENERO",
        rojo:
          data.amount1.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount1.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount1.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount1.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount1.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount1.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount1.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount1.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount1.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount1.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount1.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount1.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount1.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount1.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount1.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount1.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "FEBRERO",
        rojo:
          data.amount2.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount2.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount2.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount2.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount2.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount2.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount2.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount2.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount2.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount2.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount2.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount2.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount2.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount2.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount2.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount2.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "MARZO",
        rojo:
          data.amount3.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount3.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount3.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount3.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount3.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount3.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount3.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount3.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount3.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount3.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount3.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount3.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount3.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount3.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount3.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount3.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "ABRIL",
        rojo:
          data.amount4.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount4.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount4.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount4.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount4.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount4.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount4.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount4.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount4.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount4.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount4.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount4.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount4.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount4.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount4.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount4.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "MAYO",
        rojo:
          data.amount5.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount5.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount5.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount5.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount5.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount5.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount5.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount5.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount5.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount5.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount5.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount5.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount5.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount5.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount5.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount5.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "JUNIO",
        rojo:
          data.amount6.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount6.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount6.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount6.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount6.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount6.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount6.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount6.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount6.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount6.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount6.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount6.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount6.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount6.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount6.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount6.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "JULIO",
        rojo:
          data.amount7.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount7.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount7.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount7.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount7.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount7.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount7.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount7.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount7.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount7.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount7.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount7.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount7.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount7.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount7.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount7.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "AGOSTO",
        rojo:
          data.amount8.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount8.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount8.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount8.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount8.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount8.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount8.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount8.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount8.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount8.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount8.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount8.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount8.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount8.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount8.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount8.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "SEPTIEMBRE",
        rojo:
          data.amount9.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount9.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount9.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount9.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount9.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount9.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount9.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount9.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount9.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount9.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount9.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount9.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount9.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount9.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount9.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount9.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "OCTUBRE",
        rojo:
          data.amount10.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount10.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount10.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount10.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount10.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount10.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount10.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount10.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount10.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount10.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount10.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount10.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount10.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount10.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount10.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount10.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "NOVIEMBRE",
        rojo:
          data.amount11.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount11.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount11.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount11.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount11.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount11.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount11.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount11.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount11.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount11.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount11.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount11.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount11.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount11.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount11.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount11.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "DICIEMBRE",
        rojo:
          data.amount12.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount12.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount12.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount12.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount12.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount12.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount12.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount12.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount12.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount12.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount12.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount12.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount12.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount12.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount12.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount12.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });

      toast.current.clear();

      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const modificarTripulacionCerrada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificartripulacioncerrada",
        datos
      );

      mensajeAlerta("Tripulación Cerrada", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulación Cerrada", error.response.data, "error");
    }
  };

  const obtenerHorariosTripulacion = async (datos) => {
    const { tripulanteSeleccionado, tripulante, horario } = datos;

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhorariostripulacion",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      let data2 = null;
      if (horario === "tripulacion") {
        data2 = respuesta.data.responseServicios;
      }

      function group(arr, key) {
        return [
          ...arr
            .reduce(
              (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
              new Map()
            )
            .values(),
        ];
      }

      const local = [];

      for (var i = 0; i < data.length; i++) {
        if (
          data[i].medicos !== null ||
          data[i].choferes !== null ||
          data[i].enfermeros !== null
        ) {
          local.push({
            ID: data[i].id,
            ...(horario === "tripulacion"
              ? {
                  Tripulante: tripulante,
                }
              : {}),
            Base: data[i].bases !== null ? data[i].bases.nombre : null,
            Movil: data[i].movilId,
            NombreMedico:
              data[i].medicos !== null ? data[i].medicos.nombre : null,
            FechaIngresoMedico: data[i].medicoIngreso
              ? formatDateTime(data[i].medicoIngreso)
              : null,
            FechaSalidaMedico: data[i].medicoSalida
              ? formatDateTime(data[i].medicoSalida)
              : null,

            NombreChofer:
              data[i].choferes !== null ? data[i].choferes.nombre : null,
            FechaIngresoChofer: data[i].choferIngreso
              ? formatDateTime(data[i].choferIngreso)
              : null,
            FechaSalidaChofer: data[i].choferSalida
              ? formatDateTime(data[i].choferSalida)
              : null,

            NombreEnfermero:
              data[i].enfermeros !== null ? data[i].enfermeros.nombre : null,
            FechaIngresoEnfermero: data[i].enfermeroIngreso
              ? formatDateTime(data[i].enfermeroIngreso)
              : null,
            FechaSalidaEnfermero: data[i].enfermeroSalida
              ? formatDateTime(data[i].enfermeroSalida)
              : null,
            ...(horario === "tripulacion"
              ? {
                  CantidadServicios: data2.filter(
                    (obj) => obj.tripulacionId === data[i].id
                  ).length,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  FechaArmado: data[i].fechaArmado
                    ? formatDateTime(data[i].fechaArmado)
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  FechaCerrado: data[i].fechaCerrado
                    ? formatDateTime(data[i].fechaCerrado)
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  UsuarioArmado: data[i].usuarioArmado
                    ? data[i].usuarioArmado
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  UsuarioCerrado: data[i].usuarioActualizado
                    ? data[i].usuarioActualizado
                    : null,
                }
              : {}),

            /*
          Indicativo: data[i].indicativo,
          FechaTomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          FechaPasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          FechaSalida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          FechaLlegada: data[i].fllegada
            ? formatDateTime(data[i].fllegada)
            : null,
          FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          IDTitular: data[i].titularId,
          NombreTitular: data[i].nombreTitular,
          IDAfiliado: data[i].afiliadoId,
          NombreAfiliado: data[i].nombre,
          Domicilio: data[i].domicilio,
          Telefono: data[i].telefono,
          Documento: data[i].numeroDocumento,
          Edad: data[i].edad,
          Sexo: data[i].sexo,
          Movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
          IDTripulacion: data[i].tripulaciones
            ? data[i].tripulaciones.id
            : null,
          Base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          Medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          Chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          Enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          CoseguroAbonar: data[i].coseguroAbonar,
          CoseguroAbonado: data[i].coseguroAbonado,
          obraSocialConvenio: data[i].obraSocial,
          covid: data[i].covid ? "SI" : "NO",
          valorServicio: data[i].valorizacion,
          */
          });
        }
      }
      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;

      toast.current.clear();
      setServiciosFinalizadosExcel(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerHorariosTripulacionProgramacion = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhorariostripulacionprogramacion",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        if (
          data[i].medicos !== null ||
          data[i].choferes !== null ||
          data[i].enfermeros !== null
        ) {
          local.push({
            ID: data[i].id,
            Base: data[i].bases !== null ? data[i].bases.nombre : null,
            Movil: data[i].movilId,
            NombreMedico:
              data[i].medicos !== null ? data[i].medicos.nombre : null,
            FechaIngresoMedico: data[i].medicoIngreso
              ? formatDateTime(data[i].medicoIngreso)
              : null,
            FechaSalidaMedico: data[i].medicoSalida
              ? formatDateTime(data[i].medicoSalida)
              : null,

            NombreChofer:
              data[i].choferes !== null ? data[i].choferes.nombre : null,
            FechaIngresoChofer: data[i].choferIngreso
              ? formatDateTime(data[i].choferIngreso)
              : null,
            FechaSalidaChofer: data[i].choferSalida
              ? formatDateTime(data[i].choferSalida)
              : null,

            NombreEnfermero:
              data[i].enfermeros !== null ? data[i].enfermeros.nombre : null,
            FechaIngresoEnfermero: data[i].enfermeroIngreso
              ? formatDateTime(data[i].enfermeroIngreso)
              : null,
            FechaSalidaEnfermero: data[i].enfermeroSalida
              ? formatDateTime(data[i].enfermeroSalida)
              : null,
          });
        }
      }
      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;

      toast.current.clear();
      setServiciosFinalizadosExcel(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLiquidacionesMedicas = async (datos) => {
    const { tope, edadPediatrico, nocturnoDesde } = datos;

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerliquidacionesmedicas",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      let data2 = respuesta.data.responseServicios;
      let data3 = respuesta.data.responseMedico;
      let feriados = respuesta.data.responseFeriados;

      const local = [];

      function totalCoseguros(tripulacionId) {
        let totalCoseguro = 0;
        for (var i = 0; i < data2.length; i++) {
          if (data2[i].tripulacionId === tripulacionId) {
            totalCoseguro = totalCoseguro + Number(data2[i].coseguroAbonado);
          }
        }
        return totalCoseguro;
      }

      function totalPediatricos(tripulacionId) {
        let totalPediatrico = 0;
        for (var i = 0; i < data2.length; i++) {
          if (
            data2[i].tripulacionId === tripulacionId &&
            data2[i].edad <= edadPediatrico
          ) {
            totalPediatrico = totalPediatrico + 1;
          }
        }
        return totalPediatrico;
      }

      function bandPediatricos(tripulacionId) {
        for (var i = 0; i < data2.length; i++) {
          if (
            data2[i].tripulacionId === tripulacionId &&
            data2[i].edad <= edadPediatrico
          ) {
            return true;
          }
        }
        return false;
      }

      for (var i = 0; i < data.length; i++) {
        var allDate = data[i].medicoIngreso.replace("T", " ");
        allDate = allDate.replace(".", " ");
        allDate = allDate.split(" ");
        var thisDate = allDate[0].split("-");
        var thisTime = allDate[1].split(":");
        var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
        var newTime = [thisTime[0], thisTime[1]].join(":");

        let dArr = newDate.split("/");
        let semana = dArr[2] + "/" + dArr[1] + "/" + dArr[0];

        let diaSemana = new Date(semana).getDay();

        const index = feriados.findIndex(
          (feriado) => feriado.fecha === newDate
        );
        let time1 = newTime.split(":");
        let hora = time1[0];
        const nocturno = newTime >= nocturnoDesde;

        local.push({
          ID: data[i].id,
          Base: data[i].bases !== null ? data[i].bases.nombre : null,
          Movil: data[i].movilId,
          NombreMedico:
            data[i].medicos !== null ? data[i].medicos.nombre : null,
          FechaIngresoMedico: data[i].medicoIngreso
            ? formatDateTime(data[i].medicoIngreso)
            : null,
          FechaSalidaMedico: data[i].medicoSalida
            ? formatDateTime(data[i].medicoSalida)
            : null,
          CantidadServicios: data2.filter(
            (obj) => obj.tripulacionId === data[i].id
          ).length,
          Pediatricos: totalPediatricos(data[i].id),
          Coseguros: parseFloat(totalCoseguros(data[i].id)).toFixed(2),
          /*
          ValorGuardia: data3.valorGuardia
            ? parseFloat(data3.valorGuardia).toFixed(2)
            : parseFloat(0).toFixed(2),
           */
          ValorGuardia:
            data2.filter((obj) => obj.tripulacionId === data[i].id).length >=
            tope
              ? data3.valorGuardia
                ? parseFloat(data3.valorGuardia).toFixed(2)
                : 0
              : data3.valorGuardia
              ? parseFloat(
                  data3.valorGuardia - totalCoseguros(data[i].id)
                ).toFixed(2)
              : 0,
          Presentismo: data3.presentismo ? data3.presentismo : 0,

          AdicionalPediatrico:
            totalPediatricos(data[i].id) > 0 && data3.pediatrico
              ? parseFloat(data3.pediatrico).toFixed(2)
              : parseFloat(0).toFixed(2),

          AdicionalNocturno:
            nocturno && data3.nocturno
              ? parseFloat((data3.valorGuardia * data3.nocturno) / 100).toFixed(
                  2
                )
              : parseFloat(0).toFixed(2),
          AdicionalSadofe:
            (index !== -1 || diaSemana === 6 || diaSemana === 0) && data3.sadofe
              ? parseFloat((data3.valorGuardia * data3.sadofe) / 100).toFixed(2)
              : parseFloat(0).toFixed(2),
          /*   
          Autoliquidable:
            data2.filter((obj) => obj.tripulacionId === data[i].id).length >=
            tope
              ? parseFloat(totalCoseguros(data[i].id)).toFixed(2)
              : parseFloat(0).toFixed(2),
            */
        });
      }
      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;
      let totalGuardia = 0;
      let totalCoseguro = 0;
      let totalPresentismo = 0;
      let totalPediatrico = 0;
      let totalNocturno = 0;
      let totalSadofe = 0;
      let localExcel = [];
      for (var i = 0; i < local.length; i++) {
        if (local[i].CantidadServicios > 0) {
          localExcel.push(local[i]);
          totalGuardia = totalGuardia + Number(local[i].ValorGuardia);
          totalCoseguro = totalCoseguro + Number(local[i].Coseguros);
          totalPresentismo = totalPresentismo + Number(local[i].Presentismo);

          totalPediatrico =
            totalPediatrico + Number(local[i].AdicionalPediatrico);

          totalNocturno = totalNocturno + Number(local[i].AdicionalNocturno);
          totalSadofe = totalSadofe + Number(local[i].AdicionalSadofe);
        }
      }

      localExcel.push({
        Base: "TOTAL",
        Coseguros: parseFloat(totalCoseguro).toFixed(2),
        ValorGuardia: parseFloat(totalGuardia).toFixed(2),
        Presentismo: parseFloat(totalPresentismo).toFixed(2),
        AdicionalPediatrico: parseFloat(totalPediatrico).toFixed(2),
        AdicionalNocturno: parseFloat(totalNocturno).toFixed(2),
        AdicionalSadofe: parseFloat(totalSadofe).toFixed(2),
      });

      toast.current.clear();
      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLiquidacionesChoferes = async (datos) => {
    const { nocturnoDesde, nocturnoHasta } = datos;

    function padTo2Digits(num) {
      return String(num).padStart(2, "0");
    }

    function diff_hours(dt2, dt1) {
      dt2.setHours(dt2.getHours() + 3);
      dt1.setHours(dt1.getHours() + 3);

      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;

      return Math.abs(Math.round(diff));
    }

    function diff_min(dt2, dt1) {
      let horas = diff_hours(dt2, dt1);

      dt2.setHours(dt2.getHours() + 3);
      dt1.setHours(dt1.getHours() + 3);

      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;

      let min = Math.abs(Math.round(diff));

      let totalMinutos = horas * 60;
      let minutos = min - totalMinutos;

      return Math.abs(minutos);
    }

    function diff_time(dt) {
      dt.setHours(dt.getHours() + 3);

      const hoursAndMinutes =
        padTo2Digits(dt.getHours()) + ":" + padTo2Digits(dt.getMinutes());
      return hoursAndMinutes;
    }

    function diffTime(time1, time2) {
      var hour1 = time1.split(":")[0];
      var hour2 = time2.split(":")[0];
      var min1 = time1.split(":")[1];
      var min2 = time2.split(":")[1];
      var diff_hour = hour2 - hour1;
      var diff_min = min2 - min1;
      if (diff_hour < 0) {
        diff_hour += 24;
      }
      if (diff_min < 0) {
        diff_min += 60;
        diff_hour--;
      } else if (diff_min >= 60) {
        diff_min -= 60;
        diff_hour++;
      }
      return [diff_hour, diff_min];
    }

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerliquidacioneschoferes",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      //let data2 = respuesta.data.responseServicios;
      let data3 = respuesta.data.responseChofer;
      let feriados = respuesta.data.responseFeriados;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          ID: data[i].id,
          Base: data[i].bases !== null ? data[i].bases.nombre : null,
          Movil: data[i].movilId,
          NombreChofer:
            data[i].choferes !== null ? data[i].choferes.nombre : null,
          FechaIngresoChofer: data[i].choferIngreso
            ? formatDateTime(data[i].choferIngreso)
            : null,
          FechaSalidaChofer: data[i].choferSalida
            ? formatDateTime(data[i].choferSalida)
            : null,
          time1: diff_time(new Date(data[i].choferIngreso)),

          time2: diff_time(new Date(data[i].choferSalida)),

          ValorGuardia: data3.valorGuardia
            ? parseFloat(data3.valorGuardia).toFixed(2)
            : parseFloat(0).toFixed(2),
          ValorNocturno:
            data3.valorGuardia && data3.nocturno
              ? parseFloat(
                  Number(data3.valorGuardia) +
                    (Number(data3.valorGuardia) * Number(data3.nocturno)) / 100
                ).toFixed(2)
              : data3.valorGuardia && !data3.nocturno
              ? parseFloat(Number(data3.valorGuardia)).toFixed(2)
              : parseFloat(0).toFixed(2),
          ValorSadofe:
            data3.valorGuardia && data3.sadofe
              ? parseFloat(
                  Number(data3.valorGuardia) +
                    (Number(data3.valorGuardia) * Number(data3.sadofe)) / 100
                ).toFixed(2)
              : data3.valorGuardia && !data3.sadofe
              ? parseFloat(Number(data3.valorGuardia)).toFixed(2)
              : parseFloat(0).toFixed(2),
          horas: null,
          horasNocturnas: null,
          horasDiurnas: null,
          importeDiurnas: null,
          importeNocturnas: null,
          valorHoraDiurna: null,
          valorHoraNocturna: null,
        });
      }

      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;
      let totalDiurnas = 0;
      let totalNocturnas = 0;
      let totalFinal = 0;

      let valorDiurnas = 0;
      let valorNocturnas = 0;
      let valorSadofe = 0;
      let localExcel = [];

      let horaNoctunaDesde = "22:00";
      let horaNoctunaHasta = "06:00";
      let band = true;
      let diaFeriado = false;

      for (var i = 0; i < local.length; i++) {
        band = true;
        if (local[i].FechaIngresoChofer && local[i].FechaSalidaChofer) {
          var newDate1 = local[i].FechaIngresoChofer.substring(0, 10);
          var newDate2 = local[i].FechaSalidaChofer.substring(0, 10);

          let dArr1 = newDate1.split("/");
          let semana1 = dArr1[2] + "/" + dArr1[1] + "/" + dArr1[0];

          let dArr2 = newDate2.split("/");
          let semana2 = dArr2[2] + "/" + dArr2[1] + "/" + dArr2[0];

          let diaSemana1 = new Date(semana1).getDay();
          let diaSemana2 = new Date(semana2).getDay();

          var thisTime1 = local[i].time1.split(":");
          var newTime1 = [thisTime1[0], thisTime1[1]].join(":");

          var thisTime2 = local[i].time2.split(":");
          var newTime2 = [thisTime2[0], thisTime2[1]].join(":");

          const index1 = feriados.findIndex(
            (feriado) => feriado.fecha === newDate1
          );
          const index2 = feriados.findIndex(
            (feriado) => feriado.fecha === newDate2
          );

          let horas = [];
          let horasNocturnas = [];
          let horasDiurnas = [];

          if (newTime1 < newTime2) {
            horas = diffTime(newTime1, newTime2);

            if (newTime1 < horaNoctunaHasta) {
              horasNocturnas = diffTime(newTime1, horaNoctunaHasta);

              if (newTime2 < horaNoctunaDesde) {
                horasDiurnas = diffTime(horaNoctunaHasta, newTime2);
              } else {
                horasDiurnas = diffTime(horaNoctunaHasta, horaNoctunaDesde);
                let horasNocturnas2 = diffTime(horaNoctunaDesde, newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              }
            } else {
              if (newTime2 < horaNoctunaDesde) {
                horasDiurnas = diffTime(newTime1, newTime2);
              } else {
                horasDiurnas = diffTime(newTime1, horaNoctunaDesde);
                horasNocturnas = diffTime(horaNoctunaDesde, newTime2);
              }
            }
          } else {
            horas = diffTime(newTime1, "24:00");
            let horas2 = diffTime("00:00", newTime2);
            horas[0] = horas[0] + horas2[0];
            horas[1] = horas[1] + horas2[1];

            if (newTime1 < horaNoctunaDesde) {
              horasNocturnas = diffTime(horaNoctunaDesde, "24:00");
              horasDiurnas = diffTime(newTime1, horaNoctunaDesde);

              if (newTime2 < horaNoctunaHasta) {
                let horasNocturnas2 = diffTime("00:00", newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              } else {
                let horasNocturnas2 = diffTime("00:00", horaNoctunaHasta);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
                let horasDiurnas2 = diffTime(horaNoctunaHasta, newTime2);
                horasDiurnas[0] = horasDiurnas[0] + horasDiurnas2[0];
                horasDiurnas[1] = horasDiurnas[1] + horasDiurnas2[1];
              }
            } else {
              if (newTime2 < horaNoctunaHasta) {
                horasNocturnas = diffTime(newTime1, "24:00");
                let horasNocturnas2 = diffTime("00:00", newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              } else {
                horasNocturnas = diffTime("00:00", horaNoctunaHasta);
                horasDiurnas = diffTime(horaNoctunaHasta, newTime2);
              }
            }
          }

          if (horas && horas.length > 0) {
            if (horas[1] === 0) {
              local[i].horas = String(horas[0]);
            } else {
              local[i].horas =
                String(horas[0]) + ":" + String(horas[1]).padStart(2, "0");
            }
          } else {
            local[i].horas = "0";
            horas.push(0);
            horas.push(1);
          }

          if (horasNocturnas && horasNocturnas.length > 0) {
            if (horasNocturnas[1] === 0) {
              local[i].horasNocturnas = String(horasNocturnas[0]);
            } else {
              local[i].horasNocturnas =
                String(horasNocturnas[0]) +
                ":" +
                String(horasNocturnas[1]).padStart(2, "0");
            }
          } else {
            local[i].horasNocturnas = "0";
            horasNocturnas.push(0);
            horasNocturnas.push(0);
          }

          if (horasDiurnas && horasDiurnas.length > 0) {
            if (horasDiurnas[1] === 0) {
              local[i].horasDiurnas = String(horasDiurnas[0]);
            } else {
              local[i].horasDiurnas =
                String(horasDiurnas[0]) +
                ":" +
                String(horasDiurnas[1]).padStart(2, "0");
            }
          } else {
            local[i].horasDiurnas = "0";
            horasDiurnas.push(0);
            horasDiurnas.push(0);
          }

          if (
            index1 !== -1 ||
            index2 !== -1 ||
            diaSemana1 === 6 ||
            diaSemana1 === 0 ||
            diaSemana2 === 6 ||
            diaSemana2 === 0
          ) {
            local[i].importeDiurnas =
              Number(horasDiurnas[0]) * Number(local[i].ValorSadofe) +
              Number((horasDiurnas[1] * Number(local[i].ValorSadofe)) / 60);
            local[i].valorHoraDiurna = Number(local[i].ValorSadofe);
          } else {
            local[i].importeDiurnas =
              Number(horasDiurnas[0]) * Number(local[i].ValorGuardia) +
              Number((horasDiurnas[1] * Number(local[i].ValorGuardia)) / 60);
            local[i].valorHoraDiurna = Number(local[i].ValorGuardia);
          }

          local[i].importeNocturnas =
            Number(horasNocturnas[0]) * Number(local[i].ValorNocturno) +
            Number((horasNocturnas[1] * Number(local[i].ValorNocturno)) / 60);
          local[i].valorHoraNocturna = Number(local[i].ValorNocturno);
        } else {
          band = false;
        }

        localExcel.push({
          ID: local[i].ID,
          Base: local[i].Base,
          Movil: local[i].Movil,
          FechaIngresoChofer: local[i].FechaIngresoChofer
            ? local[i].FechaIngresoChofer
            : "",
          FechaSalidaChofer: local[i].FechaSalidaChofer
            ? local[i].FechaSalidaChofer
            : "",
          ValorHoraDiurna: band ? local[i].valorHoraDiurna : "",
          valorHoraNocturna: band ? local[i].valorHoraNocturna : "",
          Horas: band ? local[i].horas : "",
          HorasDiurnas: band ? local[i].horasDiurnas : "",
          ImporteDiurnas: band
            ? parseFloat(local[i].importeDiurnas).toFixed(2)
            : parseFloat(0).toFixed(2),
          HorasNocturnas: band ? local[i].horasNocturnas : "",
          ImporteNocturnas: band
            ? parseFloat(local[i].importeNocturnas).toFixed(2)
            : parseFloat(0).toFixed(2),
          ImporteTotal: band
            ? parseFloat(
                Number(local[i].importeNocturnas) +
                  Number(local[i].importeDiurnas)
              ).toFixed(2)
            : parseFloat(0).toFixed(2),
        });

        if (band) {
          totalDiurnas = totalDiurnas + Number(local[i].importeDiurnas);
          totalNocturnas = totalNocturnas + Number(local[i].importeNocturnas);
          totalFinal =
            totalFinal +
            Number(local[i].importeDiurnas) +
            Number(local[i].importeNocturnas);
        }
      }

      localExcel.push({
        Base: "TOTAL",
        ImporteDiurnas: parseFloat(totalDiurnas).toFixed(2),
        ImporteNocturnas: parseFloat(totalNocturnas).toFixed(2),
        ImporteTotal: parseFloat(totalFinal).toFixed(2),
      });

      toast.current.clear();
      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLiquidacionesEnfermeros = async (datos) => {
    const { nocturnoDesde, nocturnoHasta } = datos;

    function padTo2Digits(num) {
      return String(num).padStart(2, "0");
    }

    function diff_hours(dt2, dt1) {
      dt2.setHours(dt2.getHours() + 3);
      dt1.setHours(dt1.getHours() + 3);

      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;

      return Math.abs(Math.round(diff));
    }

    function diff_min(dt2, dt1) {
      let horas = diff_hours(dt2, dt1);

      dt2.setHours(dt2.getHours() + 3);
      dt1.setHours(dt1.getHours() + 3);

      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;

      let min = Math.abs(Math.round(diff));

      let totalMinutos = horas * 60;
      let minutos = min - totalMinutos;

      return Math.abs(minutos);
    }

    function diff_time(dt) {
      dt.setHours(dt.getHours() + 3);

      const hoursAndMinutes =
        padTo2Digits(dt.getHours()) + ":" + padTo2Digits(dt.getMinutes());
      return hoursAndMinutes;
    }

    function diffTime(time1, time2) {
      var hour1 = time1.split(":")[0];
      var hour2 = time2.split(":")[0];
      var min1 = time1.split(":")[1];
      var min2 = time2.split(":")[1];
      var diff_hour = hour2 - hour1;
      var diff_min = min2 - min1;
      if (diff_hour < 0) {
        diff_hour += 24;
      }
      if (diff_min < 0) {
        diff_min += 60;
        diff_hour--;
      } else if (diff_min >= 60) {
        diff_min -= 60;
        diff_hour++;
      }
      return [diff_hour, diff_min];
    }

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerliquidacionesenfermeros",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      //let data2 = respuesta.data.responseServicios;
      let data3 = respuesta.data.responseEnfermero;
      let feriados = respuesta.data.responseFeriados;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          ID: data[i].id,
          Base: data[i].bases !== null ? data[i].bases.nombre : null,
          Movil: data[i].movilId,
          NombreChofer:
            data[i].enfermeros !== null ? data[i].enfermeros.nombre : null,
          FechaIngresoEnfermero: data[i].enfermeroIngreso
            ? formatDateTime(data[i].enfermeroIngreso)
            : null,
          FechaSalidaEnfermero: data[i].enfermeroSalida
            ? formatDateTime(data[i].enfermeroSalida)
            : null,
          time1: diff_time(new Date(data[i].enfermeroIngreso)),

          time2: diff_time(new Date(data[i].enfermeroSalida)),

          ValorGuardia: data3.valorGuardia
            ? parseFloat(data3.valorGuardia).toFixed(2)
            : parseFloat(0).toFixed(2),
          ValorNocturno:
            data3.valorGuardia && data3.nocturno
              ? parseFloat(
                  Number(data3.valorGuardia) +
                    (Number(data3.valorGuardia) * Number(data3.nocturno)) / 100
                ).toFixed(2)
              : data3.valorGuardia && !data3.nocturno
              ? parseFloat(Number(data3.valorGuardia)).toFixed(2)
              : parseFloat(0).toFixed(2),
          ValorSadofe:
            data3.valorGuardia && data3.sadofe
              ? parseFloat(
                  Number(data3.valorGuardia) +
                    (Number(data3.valorGuardia) * Number(data3.sadofe)) / 100
                ).toFixed(2)
              : data3.valorGuardia && !data3.sadofe
              ? parseFloat(Number(data3.valorGuardia)).toFixed(2)
              : parseFloat(0).toFixed(2),
          horas: null,
          horasNocturnas: null,
          horasDiurnas: null,
          importeDiurnas: null,
          importeNocturnas: null,
          valorHoraDiurna: null,
          valorHoraNocturna: null,
        });
      }

      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;
      let totalDiurnas = 0;
      let totalNocturnas = 0;
      let totalFinal = 0;

      let valorDiurnas = 0;
      let valorNocturnas = 0;
      let valorSadofe = 0;
      let localExcel = [];

      let horaNoctunaDesde = "22:00";
      let horaNoctunaHasta = "06:00";
      let band = true;
      let diaFeriado = false;

      for (var i = 0; i < local.length; i++) {
        band = true;
        if (local[i].FechaIngresoEnfermero && local[i].FechaSalidaEnfermero) {
          var newDate1 = local[i].FechaIngresoEnfermero.substring(0, 10);
          var newDate2 = local[i].FechaSalidaEnfermero.substring(0, 10);

          let dArr1 = newDate1.split("/");
          let semana1 = dArr1[2] + "/" + dArr1[1] + "/" + dArr1[0];

          let dArr2 = newDate2.split("/");
          let semana2 = dArr2[2] + "/" + dArr2[1] + "/" + dArr2[0];

          let diaSemana1 = new Date(semana1).getDay();
          let diaSemana2 = new Date(semana2).getDay();

          var thisTime1 = local[i].time1.split(":");
          var newTime1 = [thisTime1[0], thisTime1[1]].join(":");

          var thisTime2 = local[i].time2.split(":");
          var newTime2 = [thisTime2[0], thisTime2[1]].join(":");

          const index1 = feriados.findIndex(
            (feriado) => feriado.fecha === newDate1
          );
          const index2 = feriados.findIndex(
            (feriado) => feriado.fecha === newDate2
          );

          let horas = [];
          let horasNocturnas = [];
          let horasDiurnas = [];

          if (newTime1 < newTime2) {
            horas = diffTime(newTime1, newTime2);

            if (newTime1 < horaNoctunaHasta) {
              horasNocturnas = diffTime(newTime1, horaNoctunaHasta);

              if (newTime2 < horaNoctunaDesde) {
                horasDiurnas = diffTime(horaNoctunaHasta, newTime2);
              } else {
                horasDiurnas = diffTime(horaNoctunaHasta, horaNoctunaDesde);
                let horasNocturnas2 = diffTime(horaNoctunaDesde, newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              }
            } else {
              if (newTime2 < horaNoctunaDesde) {
                horasDiurnas = diffTime(newTime1, newTime2);
              } else {
                horasDiurnas = diffTime(newTime1, horaNoctunaDesde);
                horasNocturnas = diffTime(horaNoctunaDesde, newTime2);
              }
            }
          } else {
            horas = diffTime(newTime1, "24:00");
            let horas2 = diffTime("00:00", newTime2);
            horas[0] = horas[0] + horas2[0];
            horas[1] = horas[1] + horas2[1];

            if (newTime1 < horaNoctunaDesde) {
              horasNocturnas = diffTime(horaNoctunaDesde, "24:00");
              horasDiurnas = diffTime(newTime1, horaNoctunaDesde);

              if (newTime2 < horaNoctunaHasta) {
                let horasNocturnas2 = diffTime("00:00", newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              } else {
                let horasNocturnas2 = diffTime("00:00", horaNoctunaHasta);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
                let horasDiurnas2 = diffTime(horaNoctunaHasta, newTime2);
                horasDiurnas[0] = horasDiurnas[0] + horasDiurnas2[0];
                horasDiurnas[1] = horasDiurnas[1] + horasDiurnas2[1];
              }
            } else {
              if (newTime2 < horaNoctunaHasta) {
                horasNocturnas = diffTime(newTime1, "24:00");
                let horasNocturnas2 = diffTime("00:00", newTime2);
                horasNocturnas[0] = horasNocturnas[0] + horasNocturnas2[0];
                horasNocturnas[1] = horasNocturnas[1] + horasNocturnas2[1];
              } else {
                horasNocturnas = diffTime("00:00", horaNoctunaHasta);
                horasDiurnas = diffTime(horaNoctunaHasta, newTime2);
              }
            }
          }

          if (horas && horas.length > 0) {
            if (horas[1] === 0) {
              local[i].horas = String(horas[0]);
            } else {
              local[i].horas =
                String(horas[0]) + ":" + String(horas[1]).padStart(2, "0");
            }
          } else {
            local[i].horas = "0";
            horas.push(0);
            horas.push(1);
          }

          if (horasNocturnas && horasNocturnas.length > 0) {
            if (horasNocturnas[1] === 0) {
              local[i].horasNocturnas = String(horasNocturnas[0]);
            } else {
              local[i].horasNocturnas =
                String(horasNocturnas[0]) +
                ":" +
                String(horasNocturnas[1]).padStart(2, "0");
            }
          } else {
            local[i].horasNocturnas = "0";
            horasNocturnas.push(0);
            horasNocturnas.push(0);
          }

          if (horasDiurnas && horasDiurnas.length > 0) {
            if (horasDiurnas[1] === 0) {
              local[i].horasDiurnas = String(horasDiurnas[0]);
            } else {
              local[i].horasDiurnas =
                String(horasDiurnas[0]) +
                ":" +
                String(horasDiurnas[1]).padStart(2, "0");
            }
          } else {
            local[i].horasDiurnas = "0";
            horasDiurnas.push(0);
            horasDiurnas.push(0);
          }

          if (
            index1 !== -1 ||
            index2 !== -1 ||
            diaSemana1 === 6 ||
            diaSemana1 === 0 ||
            diaSemana2 === 6 ||
            diaSemana2 === 0
          ) {
            local[i].importeDiurnas =
              Number(horasDiurnas[0]) * Number(local[i].ValorSadofe) +
              Number((horasDiurnas[1] * Number(local[i].ValorSadofe)) / 60);
            local[i].valorHoraDiurna = Number(local[i].ValorSadofe);
          } else {
            local[i].importeDiurnas =
              Number(horasDiurnas[0]) * Number(local[i].ValorGuardia) +
              Number((horasDiurnas[1] * Number(local[i].ValorGuardia)) / 60);
            local[i].valorHoraDiurna = Number(local[i].ValorGuardia);
          }

          local[i].importeNocturnas =
            Number(horasNocturnas[0]) * Number(local[i].ValorNocturno) +
            Number((horasNocturnas[1] * Number(local[i].ValorNocturno)) / 60);
          local[i].valorHoraNocturna = Number(local[i].ValorNocturno);
        } else {
          band = false;
        }

        localExcel.push({
          ID: local[i].ID,
          Base: local[i].Base,
          Movil: local[i].Movil,
          FechaIngresoEnfermero: local[i].FechaIngresoEnfermero
            ? local[i].FechaIngresoEnfermero
            : "",
          FechaSalidaEnfermero: local[i].FechaSalidaEnfermero
            ? local[i].FechaSalidaEnfermero
            : "",
          ValorHoraDiurna: band ? local[i].valorHoraDiurna : "",
          valorHoraNocturna: band ? local[i].valorHoraNocturna : "",
          Horas: band ? local[i].horas : "",
          HorasDiurnas: band ? local[i].horasDiurnas : "",
          ImporteDiurnas: band
            ? parseFloat(local[i].importeDiurnas).toFixed(2)
            : parseFloat(0).toFixed(2),
          HorasNocturnas: band ? local[i].horasNocturnas : "",
          ImporteNocturnas: band
            ? parseFloat(local[i].importeNocturnas).toFixed(2)
            : parseFloat(0).toFixed(2),
          ImporteTotal: band
            ? parseFloat(
                Number(local[i].importeNocturnas) +
                  Number(local[i].importeDiurnas)
              ).toFixed(2)
            : parseFloat(0).toFixed(2),
        });

        if (band) {
          totalDiurnas = totalDiurnas + Number(local[i].importeDiurnas);
          totalNocturnas = totalNocturnas + Number(local[i].importeNocturnas);
          totalFinal =
            totalFinal +
            Number(local[i].importeDiurnas) +
            Number(local[i].importeNocturnas);
        }
      }

      localExcel.push({
        Base: "TOTAL",
        ImporteDiurnas: parseFloat(totalDiurnas).toFixed(2),
        ImporteNocturnas: parseFloat(totalNocturnas).toFixed(2),
        ImporteTotal: parseFloat(totalFinal).toFixed(2),
      });

      toast.current.clear();
      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const cerrarServicioEmergencia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cerrarservicioemergencia",
        datos
      );
      mensajeAlerta("Cierre de Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
      guardarServicioSeleccionado(null);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cierre de Servicio", error.response.data, "error");
    }
  };

  //Tripulaciones

  const obtenerTripulaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulaciones",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          movil: data[i].moviles.nombre,
          detalle: data[i].moviles.detalle,
          base: data[i].bases.nombre,
          baseId: data[i].bases.id,
          telefono:
            data[i].telefonos !== null ? data[i].telefonos.nombre : null,
          telefonoId: data[i].telefonos !== null ? data[i].telefonos.id : null,
          almacen: data[i].almacenes !== null ? data[i].almacenes.nombre : null,
          almacenId: data[i].almacenes !== null ? data[i].almacenes.id : null,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          medicoId:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicoId
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          choferId:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferId
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          enfermeroId:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeroId
              : null,
          activo: data[i].activo,
          localizacion: data[i].localizacion,
          servicioId:
            data[i].servicioId !== null && data[i].servicioId !== 0
              ? data[i].servicioId
              : null,
          confirmaApp: data[i].confirmaApp ? data[i].confirmaApp : null,
          medicoEstado: data[i].medicoEstado,
          choferEstado: data[i].choferEstado,
          enfermeroEstado: data[i].enfermeroEstado,
          lat: data[i].lat,
          lon: data[i].lon,
          fechaGps: data[i].fechaGps,
          medicoIngreso:
            data[i].medicoIngreso !== null
              ? data[i].medicoIngreso.substring(0, 16)
              : "",
          choferIngreso:
            data[i].choferIngreso !== null
              ? data[i].choferIngreso.substring(0, 16)
              : "",
          enfermeroIngreso:
            data[i].enfermeroIngreso !== null
              ? data[i].enfermeroIngreso.substring(0, 16)
              : "",
          medicoSalida:
            data[i].medicoSalida !== null
              ? data[i].medicoSalida.substring(0, 16)
              : "",
          choferSalida:
            data[i].choferSalida !== null
              ? data[i].choferSalida.substring(0, 16)
              : "",
          enfermeroSalida:
            data[i].enfermeroSalida !== null
              ? data[i].enfermeroSalida.substring(0, 16)
              : "",

          cantidadServiciosPendientes: data[i].serviciospendientes.filter(
            (obj) => {
              if (
                obj.color === "VERDE" ||
                obj.color === "AMARILLO" ||
                obj.color === "ROJO" ||
                obj.color === "FUCCIA"
              ) {
                return true;
              }
              return false;
            }
          ).length,
          cantidadServiciosFinalizados: data[i].serviciosfinalizados.filter(
            (obj) => {
              if (
                obj.color === "VERDE" ||
                obj.color === "AMARILLO" ||
                obj.color === "ROJO" ||
                obj.color === "FUCCIA"
              ) {
                return true;
              }
              return false;
            }
          ).length,
        });
      }

      guardarTripulaciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  //Tripulaciones

  const obtenerTripulacionesDespacho = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacionesdespacho",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          servicioId:
            data[i].servicioId !== null && data[i].servicioId !== 0
              ? data[i].servicioId
              : null,
          confirmaApp: data[i].confirmaApp ? data[i].confirmaApp : null,
        });
      }

      guardarTripulaciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTripulacionesProgramacion = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacionesprogramacion"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          movil: data[i].moviles.nombre,
          base: data[i].bases.nombre,
          baseId: data[i].bases.id,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          medicoId:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicoId
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          choferId:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferId
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          enfermeroId:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeroId
              : null,
          activo: data[i].activo,
          medicoIngreso:
            data[i].medicoIngreso !== null
              ? data[i].medicoIngreso.substring(0, 16)
              : "",
          choferIngreso:
            data[i].choferIngreso !== null
              ? data[i].choferIngreso.substring(0, 16)
              : "",
          enfermeroIngreso:
            data[i].enfermeroIngreso !== null
              ? data[i].enfermeroIngreso.substring(0, 16)
              : "",
          medicoSalida:
            data[i].medicoSalida !== null
              ? data[i].medicoSalida.substring(0, 16)
              : "",
          choferSalida:
            data[i].choferSalida !== null
              ? data[i].choferSalida.substring(0, 16)
              : "",
          enfermeroSalida:
            data[i].enfermeroSalida !== null
              ? data[i].enfermeroSalida.substring(0, 16)
              : "",
        });
      }

      guardarTripulacionesProgramacion(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTripulacionesCerradas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacionescerradas",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          movil: data[i].moviles.nombre,
          base: data[i].bases.nombre,
          baseId: data[i].bases.id,
          telefono:
            data[i].telefonos !== null ? data[i].telefonos.nombre : null,
          telefonoId: data[i].telefonos !== null ? data[i].telefonos.id : null,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          medicoId:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicoId
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          choferId:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferId
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          enfermeroId:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeroId
              : null,
          activo: data[i].activo,
          localizacion: data[i].localizacion,
          servicioId:
            data[i].servicioId !== null && data[i].servicioId !== 0
              ? data[i].servicioId
              : null,
          confirmaApp: data[i].confirmaApp ? data[i].confirmaApp : null,
          medicoEstado: data[i].medicoEstado,
          choferEstado: data[i].choferEstado,
          enfermeroEstado: data[i].enfermeroEstado,
          lat: data[i].lat,
          lon: data[i].lon,
          fechaGps: data[i].fechaGps,
          medicoIngreso:
            data[i].medicoIngreso !== null
              ? data[i].medicoIngreso.substring(0, 16)
              : "",
          choferIngreso:
            data[i].choferIngreso !== null
              ? data[i].choferIngreso.substring(0, 16)
              : "",
          enfermeroIngreso:
            data[i].enfermeroIngreso !== null
              ? data[i].enfermeroIngreso.substring(0, 16)
              : "",
          medicoSalida:
            data[i].medicoSalida !== null
              ? data[i].medicoSalida.substring(0, 16)
              : "",
          choferSalida:
            data[i].choferSalida !== null
              ? data[i].choferSalida.substring(0, 16)
              : "",
          enfermeroSalida:
            data[i].enfermeroSalida !== null
              ? data[i].enfermeroSalida.substring(0, 16)
              : "",
        });
      }

      guardarTripulacionesCerradas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTripulacionesLiberados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulaciones",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        if (data[i].servicioId === null) {
          local.push({
            id: data[i].id,
            movil: data[i].movilId,
            base: data[i].bases.nombre,
            telefono:
              data[i].telefonos !== null ? data[i].telefonos.nombre : null,
            telefonoId:
              data[i].telefonos !== null ? data[i].telefonos.id : null,
            medico: data[i].medicoId !== 0 ? data[i].medicos.nombre : null,
            medicoId: data[i].medicoId !== 0 ? data[i].medicoId : null,
            chofer: data[i].choferId !== 0 ? data[i].choferes.nombre : null,
            choferId: data[i].choferId !== 0 ? data[i].choferId : null,
            enfermero:
              data[i].enfermeroId !== 0 ? data[i].enfermeros.nombre : null,
            enfermeroId: data[i].enfermeroId !== 0 ? data[i].enfermeroId : null,
            activo: data[i].activo,
            localizacion: data[i].localizacion,
            servicioId: data[i].servicioId,
          });
        }
      }

      guardarTripulaciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const agregarTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregartripulacion",
        datos
      );

      let data = respuesta.data;
      mensajeAlerta("Tripulaciones", data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones", error.response.data, "error");
    }
  };

  const agregarTripulacionProgramacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregartripulacionprogramacion",
        datos
      );

      let data = respuesta.data;
      mensajeAlerta("Tripulaciones Programadas", data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones Programadas", error.response.data, "error");
    }
  };

  const obtenerTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacion",
        datos
      );

      let data = respuesta.data;

      if (data.medicoIngreso !== null && data.medicoIngreso !== "") {
        data.medicoIngreso = data.medicoIngreso.substring(0, 16);
      }
      if (data.medicoSalida !== null && data.medicoSalida !== "") {
        data.medicoSalida = data.medicoSalida.substring(0, 16);
      }

      if (data.choferIngreso !== null && data.choferIngreso !== "") {
        data.choferIngreso = data.choferIngreso.substring(0, 16);
      }
      if (data.choferSalida !== null && data.choferSalida !== "") {
        data.choferSalida = data.choferSalida.substring(0, 16);
      }

      if (data.enfermeroIngreso !== null && data.enfermeroIngreso !== "") {
        data.enfermeroIngreso = data.enfermeroIngreso.substring(0, 16);
      }
      if (data.enfermeroSalida !== null && data.enfermeroSalida !== "") {
        data.enfermeroSalida = data.enfermeroSalida.substring(0, 16);
      }
      if (data.localizacion === null) {
        data.localizacion = false;
      }

      //guardarTripulacion(data);
      guardarTripulacionModificada(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones", error.response.data, "error");
    }
  };

  const actualizarTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizartripulacion",
        datos
      );

      mensajeAlerta("Tripulaciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones", error.response.data, "error");
    }
  };

  const actualizarTripulacionProgramacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizartripulacionprogramacion",
        datos
      );

      mensajeAlerta("Tripulaciones Programadas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones Programadas", error.response.data, "error");
    }
  };

  const borrarTripulacionProgramacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/borrartripulacionprogramacion",
        datos
      );

      mensajeAlerta("Tripulaciones Programadas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones Programadas", error.response.data, "error");
    }
  };

  const actualizarTripulacionCambiada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizartripulacioncambiada",
        datos
      );

      mensajeAlerta("Tripulaciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tripulaciones", error.response.data, "error");
    }
  };

  const enviarNotificaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/enviarnotificaciones",
        datos
      );

      mensajeAlerta("Notificaciones", respuesta.data, "success");
      //localStorage.setItem("refrescar", new Date());
      //setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notificaciones", error.response.data, "error");
    }
  };

  const asignarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/asignarmovil",
        datos
      );

      mensajeAlerta("Asignar Móvil", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Asignar Móvil", error.response.data, "error");
    }
  };

  const asignarMovilDerivado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/asignarmovilderivado",
        datos
      );

      mensajeAlerta("Asignar Móvil Derivado", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Asignar Móvil Derivado", error.response.data, "error");
    }
  };

  const salidaMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/salidamovil",
        datos
      );

      mensajeAlerta("Salida Móvil", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Salida Móvil", error.response.data, "error");
    }
  };

  const llegadaMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/llegadamovil",
        datos
      );

      mensajeAlerta("Llegada Móvil", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Llegada Móvil", error.response.data, "error");
    }
  };

  const anularServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/anularservicio",
        datos
      );

      mensajeAlerta("Anular Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Anular Servicio", error.response.data, "error");
    }
  };

  const modificarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificarservicio",
        datos
      );

      mensajeAlerta("Modificar Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Modificar Servicio", error.response.data, "error");
    }
  };

  const modificarServicioFinalizado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificarserviciofinalizado",
        datos
      );

      mensajeAlerta("Servicios Finalizados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Servicios Finalizados", error.response.data, "error");
    }
  };

  const activarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/activarservicio",
        datos
      );

      mensajeAlerta("Activar Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Activar Servicio", error.response.data, "error");
    }
  };

  const simultaneoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/simultaneoservicio",
        datos
      );

      mensajeAlerta("Servicio Simultáneo", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Servicio Simultáneo", error.response.data, "error");
    }
  };

  const apoyoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/apoyoservicio",
        datos
      );

      mensajeAlerta("Apoyo de Móvil", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Apoyo de Móvil", error.response.data, "error");
    }
  };

  const elevarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/elevarservicio",
        datos
      );

      mensajeAlerta("Elevar Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Elevar Servicio", error.response.data, "error");
    }
  };

  const reclamarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/reclamarservicio",
        datos
      );

      mensajeAlerta("Reclamar Servicio", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Reclamar Servicio", error.response.data, "error");
    }
  };

  const auditarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/auditarservicio",
        datos
      );

      mensajeAlerta("Auditar Servicio", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Auditar Servicio", error.response.data, "error");
    }
  };



  const confirmarNuevoServicioPrestador = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/confirmarnuevoservicioprestador",
        datos
      );

      //mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Confirmar Servicio", error.response.data, "error");
    }
  };

  const confirmarMovilLiberado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/confirmarmovilliberado",
        datos
      );

      //mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Confirmar Móvil Liberado", error.response.data, "error");
    }
  };

  const cambiarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cambiarmotivoconsulta",
        datos
      );

      mensajeAlerta("Cambiar Motivo de Consulta", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cambiar Motivo de Consulta", error.response.data, "error");
    }
  };

  const liberarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/liberarmovil",
        datos
      );

      mensajeAlerta("Liberar Móvil", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Liberar Móvil", error.response.data, "error");
    }
  };

  // Moviles Derivados

  const obtenerMovilesDerivados = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermovilesderivados"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movil: data[i].movilId,
          nombre: data[i].nombre,
          detalle: data[i].detalle,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoServicioId: data[i].derivadoServicioId,
          edadPediatrico: data[i].pediatrico ? data[i].pediatrico : null,
          nocturnoDesde: data[i].nocturnoDesde ? data[i].nocturnoDesde : null,
          nocturnoHasta: data[i].nocturnoHasta ? data[i].nocturnoHasta : null,
        });
      }

      //console.log(local)
      guardarMovilesDerivados(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // Traslados

  function convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const obtenerTraslados = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertraslados"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          llegada: formatDateTime(data[i].llegada),
          afiliadoId: data[i].afiliadoId,
          afiliadoNombre: data[i].nombre,
          afiliadoTitular: data[i].nombreTitular,
          domicilioOrigen: data[i].domicilio,
          //Traslados
          domicilioDestino: data[i].trasladosProgramados.destino,
          tipoMovil: data[i].trasladosProgramados.tiposMoviles.nombre,
          espera: data[i].trasladosProgramados.espera,
          retorno: data[i].trasladosProgramados.retorno,
          ftomadoAzul: formatDateTime(data[i].trasladosProgramados.ftomado),
          usuarioTomadoAzul: data[i].trasladosProgramados.usuarioTomado,
          trasladoId: data[i].trasladosProgramados.id,
          //
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,
        });
      }

      guardarTraslados(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertrasladoservicio",
        datos
      );
      let data = respuesta.data;

      guardarTrasladoServicio(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladosServicios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertrasladosservicios",
        datos
      );
      let data = respuesta.data;

      guardarTrasladosServicios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladosServiciosPendientes = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertrasladosserviciospendientes"
      );
      let data = respuesta.data;
      guardarTrasladosServicios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarTrasladoProgramado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminartrasladoprogramado",
        datos
      );

      mensajeAlerta("Traslado Programado", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Traslado Programado", error.response.data, "error");
    }
  };

  const iniciarTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/iniciartrasladoservicio",
        datos
      );
      mensajeAlerta("Traslados", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Traslados", error.response.data, "error");
    }
  };

  const finalizarTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/finalizartrasladoservicio",
        datos
      );
      mensajeAlerta("Traslados", respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Traslados", error.response.data, "error");
    }
  };

  //Eventos

  const obtenerEventos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenereventos"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          llegada: formatDateTime(data[i].llegada),
          afiliadoId: data[i].afiliadoId,
          afiliadoNombre: data[i].nombre,
          afiliadoTitular: data[i].nombreTitular,
          domicilioOrigen: data[i].domicilio,
          //Eventos
          duracion: data[i].eventosProgramados.duracion,
          cantidadMoviles: data[i].eventosProgramados.cantidadMoviles,
          dotacion: data[i].eventosProgramados.dotacion,
          tipoEvento: data[i].eventosProgramados.tipoEvento,
          responsable: data[i].eventosProgramados.responsable,
          contacto: data[i].eventosProgramados.contacto,
          pedido: formatDate(data[i].eventosProgramados.pedido),
          telefono: data[i].eventosProgramados.telefono,
          cantidadPersonas: data[i].eventosProgramados.cantidadPersonas,
          descripcion: data[i].eventosProgramados.descripcion,
          ftomadoNaranja: formatDateTime(data[i].eventosProgramados.ftomado),
          usuarioTomadoNaranja: data[i].eventosProgramados.usuarioTomado,
          eventoId: data[i].eventosProgramados.id,
          //
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obrasocial: data[i].obrasocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,
        });
      }

      guardarEventos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarEventoProgramado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminareventoprogramado",
        datos
      );

      mensajeAlerta("Eventos Programados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Eventos Programados", error.response.data, "error");
    }
  };

  const agregarNota = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnota",
        datos
      );

      mensajeAlerta("Notas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notas", error.response.data, "error");
    }
  };

  const agregarNotaPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnotapendiente",
        datos
      );

      mensajeAlerta("Notas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notas", error.response.data, "error");
    }
  };

  const obtenerNotas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotas",
        datos
      );
      let data = respuesta.data;

      const local = [];

      let notaAnt = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].nota !== notaAnt) {
          local.push({
            id: data[i].id,
            nota: data[i].nota,
            fecha: formatDateTime(data[i].fecha),
            usuario: data[i].usuario,
            servicioId: data[i].servicioId,
          });
          notaAnt = data[i].nota;
        }
      }

      setNotas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerNotasPendientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotaspendientes",
        datos
      );
      let data = respuesta.data;

      const local = [];

      let notaAnt = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].nota !== notaAnt) {
          local.push({
            id: data[i].id,
            nota: data[i].nota,
            fecha: formatDateTime(data[i].fecha),
            usuario: data[i].usuario,
            servicioId: data[i].servicioId,
          });
          notaAnt = data[i].nota;
        }
      }

      setNotas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const cancelarNotificationApp = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cancelarnotificacionapp",
        datos
      );

      mensajeAlerta("Cancelación de Solicitud", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cancelación de Solicitud", error.response.data, "error");
    }
  };

  const uploadFiles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/uploadfiles",
        datos
      );
      mensajeAlerta("Subir Archivos", respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Subir Archivos", error.response.data, "error");
    }
  };

  const obtenerDirecciones_ = async (datos) => {
    const { domicilio } = datos;

    try {
      const respuesta = await clienteAxios.get(
        "https://apis.datos.gob.ar/georef/api/direcciones?direccion=" +
          domicilio
      );
      let data = respuesta.data.direcciones;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: i,
          label: data[i].nomenclatura,
          localidad: data[i].localidad_censal.nombre,
          provincia: data[i].provincia.nombre,
          lat: data[i].ubicacion.lat,
          lon: data[i].ubicacion.lon,
        });
      }

      local.sort(function (a, b) {
        if (a.provincia > b.provincia) {
          return 1;
        }
        if (a.provincia < b.provincia) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      guardarDirecciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDirecciones = async (datos) => {
    const { domicilio, provincia } = datos;

    let dom = domicilio.replace(/ /g, "%20");
    let pro = provincia.replace(/ /g, "%20");

    /*
    var config = {
      method: 'get',
      url: `https://api.geoapify.com/v1/geocode/search?text=${dom}&Argentina&lang=es&limit=3&apiKey=${process.env.REACT_APP_GEOAPIFY_API_KEY}`,
      headers: { }
    };
    */

    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${dom}%20${pro}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      headers: {},
    };

    try {
      const respuesta = await Axios(config);

      let result = respuesta.data;
      let data = respuesta.data.results;
      let fields = data[0].address_components;
      let geometry = data[0].geometry.location;

      let locality = fields.find((element) => element.types[0] === "locality");
      let areaLevel1 = fields.find(
        (element) => element.types[0] === "administrative_area_level_1"
      );

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: i,
          label: data[i].formatted_address,
          localidad: locality !== undefined ? locality.short_name : "",
          provincia: areaLevel1 !== undefined ? areaLevel1.short_name : "",
          lat: geometry.lat,
          lon: geometry.lng,
        });
      }

      /*
      local.sort(function (a, b) {
        if (a.provincia > b.provincia) {
          return 1;
        }
        if (a.provincia < b.provincia) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      */

      guardarDirecciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerMapa = async (datos) => {
    const { lat, lon } = datos;

    var config = {
      method: "get",
      //url: `https://api.geoapify.com/v1/geocode/search?text=${dom}%20argentina&lang=es&limit=3&apiKey=${process.env.REACT_APP_GEOAPIFY_API_KEY}`,
      url: `https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=600&height=400&center=lonlat:${lon},${lat}&zoom=14&marker=lonlat:${lon},${lat}&apiKey=${process.env.REACT_APP_GEOAPIFY_API_KEY}`,
      headers: {},
    };

    /*
    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${domicilio}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      headers: {},
    };
    */
    try {
      await Axios(config);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDestinosPami = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdestinospami",
        datos
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          salida: new Date(),
          destino:
            data[i].streetName +
            " " +
            data[i].houseNumber +
            " " +
            data[i].city +
            " ( " +
            data[i].description +
            " ) ",
          descripcion: data[i].description,
          lat: "",
          lon: "",
          provincia: data[i].province,
        });
      }
      guardarTrasladosServiciosPami(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const procesoPami = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Proceso de Exportacion PAMI",
        detail: "Iniciando proceso. Este proceso puede demorar unos minutos.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciospami",
        datos
      );

      toast.current.clear();

      let data = respuesta.data;
      if (data.length === 0) {
        mensajeAlerta(
          "Proceso de Exportación PAMI",
          "No hay servicios a exportar ",
          "error"
        );
      }

      guardarProcesos(data);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <Provider
        value={{
          numeroTelefono,
          valBusqueda,
          rows,
          afiliado,
          titular,
          selectedRow,
          numeroIndicativo,
          despachos,
          resumen,
          estadoTomado,
          estadoTomadoMapa,
          servicio,
          servicioModificado,
          tripulaciones,
          tripulacionesCerradas,
          tripulacion,
          tripulacionModo,
          drawer,
          openDrawer,
          servicioSeleccionado,
          cierreEmergencias,
          cierreMedicamentos,
          cierreFuccia,
          chipData,
          tomaAzul,
          tomaNaranja,
          openModalAzul,
          openModalFuccia,
          openModalNaranja,
          traslados,
          eventos,
          notas,
          nota,
          notaVisible,
          refrescar,
          movilMarcado,
          movilesDerivados,
          serviciosFinalizados,
          serviciosAnulados,
          trasladosServicios,
          trasladoServicio,
          filtrosListadosServicios,
          serviciosFinalizadosExcel,
          serviciosAnuladosExcel,
          valorizacion,
          cantidadServicios,
          demorados,
          tripulacionModificada,
          direcciones,
          tripulacionesProgramacion,
          trasladosServiciosPami,
          statusEstadoMoviles,
          procesos,
          openMp,
          guardarValBusqueda,
          capturarTelefonoPaciente,
          obtenerDatosPacientes,
          obtenerDatosTitulares,
          guardarAfiliado,
          guardarTitular,
          buscarTitular,
          buscarValorizacion,
          setSelectedRow,
          agregarNuevoServicio,
          agregarNuevoServicioPendiente,
          setRows,
          guardarValBusqueda,
          obtenerServiciosPendientes,
          obtenerServicio,
          setEstadoTomado,
          setEstadoTomadoMapa,
          guardarServicio,
          guardarServicioModificado,
          agregarTripulacion,
          guardarTripulaciones,
          guardarTripulacionesCerradas,
          guardarTripulacion,
          obtenerTripulacion,
          obtenerTripulaciones,
          obtenerTripulacionesDespacho,
          obtenerTripulacionesCerradas,
          guardarDemorados,
          guardarTripulacionModo,
          actualizarTripulacion,
          actualizarTripulacionCambiada,
          obtenerTripulacionesLiberados,
          setDrawer,
          setOpenDrawer,
          guardarServicioSeleccionado,
          asignarMovil,
          asignarMovilDerivado,
          salidaMovil,
          llegadaMovil,
          anularServicio,
          modificarServicio,
          activarServicio,
          simultaneoServicio,
          apoyoServicio,
          guardarCierreEmergencias,
          guardarCierreMedicamentos,
          guardarCierreFuccia,
          setChipData,
          guardarTomaAzul,
          guardarTomaNaranja,
          setOpenModalAzul,
          setOpenModalFuccia,
          setOpenModalNaranja,
          guardarTraslados,
          obtenerTraslados,
          obtenerEventos,
          eliminarTrasladoProgramado,
          eliminarEventoProgramado,
          agregarNota,
          agregarNotaPendiente,
          obtenerNotas,
          obtenerNotasPendientes,
          setNota,
          setNotaVisible,
          setRefrescar,
          liberarMovil,
          setMovilMarcado,
          cerrarServicioEmergencia,
          obtenerMovilesDerivados,
          guardarMovilesDerivados,
          enviarNotificaciones,
          cancelarNotificationApp,
          guardarServiciosFinalizados,
          guardarServiciosAnulados,
          obtenerServiciosFinalizados,
          obtenerServiciosAnulados,
          guardarTrasladosServicios,
          iniciarTrasladoServicio,
          finalizarTrasladoServicio,
          obtenerTrasladoServicio,
          obtenerTrasladosServicios,
          uploadFiles,
          guardarFiltrosListadosServicios,
          obtenerTrasladosServiciosPendientes,
          obtenerDatosConvenios,
          guardarValorizacion,
          elevarServicio,
          reclamarServicio,
          cambiarMotivoConsulta,
          confirmarMovilLiberado,
          obtenerCantidadServiciosTitular,
          obtenerHorariosTripulacion,
          obtenerServicioFinalizado,
          modificarServicioFinalizado,
          verificarEstadoMoviles,
          guardarTripulacionModificada,
          modificarTripulacionCerrada,
          obtenerServiciosAnual,
          obtenerLiquidacionesMedicas,
          obtenerDirecciones,
          obtenerMapa,
          guardarDirecciones,
          obtenerTrasladosServicios,
          obtenerServiciosSemanales,
          buscarValorizacionDerivado,
          confirmarNuevoServicioPrestador,
          obtenerTripulacionesProgramacion,
          agregarTripulacionProgramacion,
          actualizarTripulacionProgramacion,
          borrarTripulacionProgramacion,
          obtenerHorariosTripulacionProgramacion,
          obtenerDestinosPami,
          setStatusEstadoMoviles,
          obtenerLiquidacionesChoferes,
          obtenerLiquidacionesEnfermeros,
          procesoPami,
          guardarProcesos,
          setNotas,
          setOpenMp,
          auditarServicio,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { ServiciosProvider, Consumer as ServiciosConsumer, ServiciosContext };
